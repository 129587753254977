:local .title {
  letter-spacing: -.5px;
  color: #222;
  margin-bottom: 36px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  position: relative;
}

:local .title:after {
  content: "";
  background-color: #436b93;
  width: 60px;
  height: 2px;
  margin: 18px auto 0;
  position: absolute;
  bottom: -18px;
  left: 0;
}
