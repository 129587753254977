:local .bottomBorder {
  width: 60px;
  height: 2px;
  margin: 0 auto;
  background-color: #436b93;
}
:local .bottomBorder.small, :local .bottomBorder.medium {
  margin-top: 9px;
}
:local .bottomBorder.large, :local .bottomBorder.xLarge, :local .bottomBorder.xxLarge {
  margin-top: 18px;
}
:local .bottomBorder.isTrade {
  background-color: #0f8d88;
}