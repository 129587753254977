.wrapper {
  padding: 2px 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

@media (width <= 568px) {
  .wrapper {
    padding: 2px 5px;
  }
}

.wrapper.disabled {
  cursor: not-allowed;
  color: #bbb;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.day {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.day:hover {
  background-color: #dee3eb;
  transition: background-color .3s;
}

.day.selected {
  color: #fff;
  background-color: #436b93;
}

.day.today {
  border: 1px solid #ddd;
}

.day.outside {
  color: #bbb;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
