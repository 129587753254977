:local .button {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  box-shadow: none;
  box-sizing: border-box;
  transition: all 175ms linear;
  position: relative;
}
:local .button:focus-visible {
  outline: none;
}
:local .button:focus-visible::after {
  z-index: 10030;
  content: "";
  border-radius: 2px;
  position: absolute;
  box-shadow: 0 0 2px 2px #204664;
  left: -1px;
  right: -1px;
  bottom: -1px;
  top: -1px;
}
:local .button:global(.focus-visible) {
  outline: none;
  outline: none;
}
:local .button:global(.focus-visible)::after {
  z-index: 10030;
  content: "";
  border-radius: 2px;
  position: absolute;
  box-shadow: 0 0 2px 2px #204664;
  left: -1px;
  right: -1px;
  bottom: -1px;
  top: -1px;
}
:local .button.disabled {
  background-color: #ddd;
  color: #fff;
  border: 0;
  cursor: not-allowed;
}
:local .primaryButton {
  color: #fff;
  background-color: #436b93;
}
:local .primaryButton:hover:not(.disabled) {
  color: #fff;
  background-color: #204664;
}