@media print {
  :global * {
    text-shadow: none !important;
    color: #000 !important;
    box-shadow: none !important;
    background: none !important;
  }

  :global a {
    text-decoration: underline;
  }

  :global a:visited {
    text-decoration: underline;
  }

  :global a[href]:after {
    content: " (" attr(href) ")";
  }

  :global abbr[title]:after {
    content: " (" attr(title) ")";
  }

  :global a[href^="javascript:"]:after {
    content: "";
  }

  :global a[href^="#"]:after {
    content: "";
  }

  :is(:global pre, :global blockquote) {
    page-break-inside: avoid;
    border: 1px solid #888;
  }

  :global thead {
    display: table-header-group;
  }

  :is(:global tr, :global img) {
    page-break-inside: avoid;
  }

  :global img {
    max-width: 100% !important;
  }

  @page {
  }

  :is(:global p, :global h2, :global h3) {
    orphans: 3;
    widows: 3;
  }

  :is(:global h2, :global h3) {
    page-break-after: avoid;
  }

  :global select {
    background: #fff !important;
  }

  :global .navbar {
    display: none;
  }

  :is(:global .table td, :global .table th) {
    background-color: #fff !important;
  }

  :global .btn > .caret {
    border-top-color: #000 !important;
  }

  :global .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }

  :global .label {
    border: 1px solid #000;
  }

  :global .table {
    border-collapse: collapse !important;
  }

  :is(:global .table-bordered th, :global .table-bordered td) {
    border: 1px solid #ddd !important;
  }
}

:global img {
  vertical-align: middle;
}

:global .img-responsive {
  max-width: 100%;
  height: auto;
  display: block;
}

:global .img-rounded {
  border-radius: 2px;
}

:is(:global .img-thumbnail, :global .thumbnail) {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 2px;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.5;
  transition: all .2s ease-in-out;
  display: inline-block;
}

:global .img-circle {
  border-radius: 50%;
}

:global hr {
  border: 0;
  border-top: 1px solid #ddd;
  margin-top: 15px;
  margin-bottom: 15px;
}

:global .sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:global p {
  margin: 0 0 10px;
}

:global .lead {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

@media (width <= 768px) {
  :global .lead {
    font-size: 18px;
  }
}

:global small {
  font-size: 12px;
}

:global .small {
  font-size: 12px;
}

:global .medium {
  font-size: 16px;
}

:global .large {
  font-size: 18px;
}

:global cite {
  font-style: normal;
}

:global .text-muted {
  color: #888;
}

:global .lightly-muted {
  color: #ddd;
}

:global .text-primary {
  color: #436b93;
}

:global .text-warning {
  color: #ffc100;
}

:is(:global .text-danger, :global .text-error) {
  color: #c00;
}

:global .text-success {
  color: #66a559;
}

:global .text-info {
  color: #436b93;
}

:global .text-aqua {
  color: #0f8d88;
}

:global .text-grey {
  color: #888;
}

:global .text-yellow {
  color: #c2a661;
}

:global .text-red {
  color: #c00;
}

:global .text-green {
  color: #559b5e;
}

:global .text-left {
  text-align: left;
}

:global .text-right {
  text-align: right;
}

:global .text-center {
  text-align: center;
}

:global .text-upper {
  text-transform: uppercase;
}

:global .text-lower {
  text-transform: lowercase;
}

:global .text-notrans {
  text-transform: none;
}

:global h1 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global h2 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global h3 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global h4 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global h5 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global h6 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global .h1 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global .h2 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global .h3 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global .h4 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global .h5 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global .h6 {
  color: inherit;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
  line-height: 1.1;
}

:global h1 small {
  color: #888;
  line-height: 1;
}

:global h1 .small {
  color: #888;
  line-height: 1;
}

:global h2 small {
  color: #888;
  line-height: 1;
}

:global h2 .small {
  color: #888;
  line-height: 1;
}

:global h3 small {
  color: #888;
  line-height: 1;
}

:global h3 .small {
  color: #888;
  line-height: 1;
}

:global h4 small {
  color: #888;
  line-height: 1;
}

:global h4 .small {
  color: #888;
  line-height: 1;
}

:global h5 small {
  color: #888;
  line-height: 1;
}

:global h5 .small {
  color: #888;
  line-height: 1;
}

:global h6 small {
  color: #888;
  line-height: 1;
}

:global h6 .small {
  color: #888;
  line-height: 1;
}

:global .h1 small {
  color: #888;
  line-height: 1;
}

:global .h1 .small {
  color: #888;
  line-height: 1;
}

:global .h2 small {
  color: #888;
  line-height: 1;
}

:global .h2 .small {
  color: #888;
  line-height: 1;
}

:global .h3 small {
  color: #888;
  line-height: 1;
}

:global .h3 .small {
  color: #888;
  line-height: 1;
}

:global .h4 small {
  color: #888;
  line-height: 1;
}

:global .h4 .small {
  color: #888;
  line-height: 1;
}

:global .h5 small {
  color: #888;
  line-height: 1;
}

:global .h5 .small {
  color: #888;
  line-height: 1;
}

:global .h6 small {
  color: #888;
  line-height: 1;
}

:global .h6 .small {
  color: #888;
  line-height: 1;
}

:is(:global h1, :global h2, :global h3) {
  margin-top: 20px;
  margin-bottom: 10px;
}

:global h1 small {
  font-size: 65%;
}

:global h1 .small {
  font-size: 65%;
}

:global h2 small {
  font-size: 65%;
}

:global h2 .small {
  font-size: 65%;
}

:global h3 small {
  font-size: 65%;
}

:global h3 .small {
  font-size: 65%;
}

:is(:global h4, :global h5, :global h6) {
  margin-top: 10px;
  margin-bottom: 10px;
}

:global h4 small {
  font-size: 75%;
}

:global h4 .small {
  font-size: 75%;
}

:global h5 small {
  font-size: 75%;
}

:global h5 .small {
  font-size: 75%;
}

:global h6 small {
  font-size: 75%;
}

:global h6 .small {
  font-size: 75%;
}

:global h1 {
  font-size: 32px;
}

:global .h1 {
  font-size: 32px;
}

:global h2 {
  font-size: 28px;
}

:global .h2 {
  font-size: 28px;
}

:global h3 {
  font-size: 24px;
}

:global .h3 {
  font-size: 24px;
}

:global h4 {
  font-size: 20px;
}

:global .h4 {
  font-size: 20px;
}

:global h5 {
  font-size: 16px;
}

:global .h5 {
  font-size: 16px;
}

:global h6 {
  font-size: 12px;
}

:global .h6 {
  font-size: 12px;
}

:global .page-header {
  border-bottom: 1px solid #ddd;
  margin: 40px 0 20px;
  padding-bottom: 9px;
}

:is(:global ul, :global ol) {
  margin-top: 0;
  margin-bottom: 10px;
}

:is(:global ul ul, :global ul ol, :global ol ul, :global ol ol) {
  margin-bottom: 0;
}

:is(:global .list-unstyled, :global .list-inline) {
  padding-left: 0;
  list-style: none;
}

:global .list-inline > li {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

:global .list-inline > li:first-child {
  padding-left: 0;
}

:global .list-inline > li:last-child {
  padding-right: 0;
}

:global dl {
  margin-bottom: 20px;
}

:is(:global dt, :global dd) {
  line-height: 1.5;
}

:global dt {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

:global dd {
  margin-left: 0;
}

@media (width >= 769px) {
  :global .dl-horizontal dt {
    float: left;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 160px;
    overflow: hidden;
  }

  :global .dl-horizontal dd {
    margin-left: 180px;
  }

  :global .dl-horizontal dd:before {
    content: " ";
    display: table;
  }

  :global .dl-horizontal dd:after {
    content: " ";
    display: table;
  }

  :global .dl-horizontal dd:after {
    clear: both;
  }
}

:is(:global abbr[title], :global abbr[data-original-title]) {
  cursor: help;
  border-bottom: 1px dotted #888;
}

:global abbr.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

:global blockquote {
  border-left: 5px solid #ddd;
  margin: 0 0 20px;
  padding: 10px 20px;
}

:global blockquote p {
  font-size: 18px;
  line-height: 1.25;
}

:global blockquote p:last-child {
  margin-bottom: 0;
}

:global blockquote small {
  color: #888;
  line-height: 1.5;
  display: block;
}

:global blockquote small:before {
  content: "— ";
}

:global blockquote.pull-right {
  border-left: 0;
  border-right: 5px solid #ddd;
  padding-left: 0;
  padding-right: 15px;
}

:global blockquote.pull-right p {
  text-align: right;
}

:global blockquote.pull-right small {
  text-align: right;
}

:global blockquote.pull-right .small {
  text-align: right;
}

:global blockquote.pull-right small:before {
  content: "";
}

:global blockquote.pull-right .small:before {
  content: "";
}

:global blockquote.pull-right small:after {
  content: " —";
}

:global blockquote.pull-right .small:after {
  content: " —";
}

:global blockquote:before {
  content: "";
}

:global blockquote:after {
  content: "";
}

:global address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.5;
}

:global .neue {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
}

:global .bryant {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
}

:is(:global code, :global kbd, :global pre, :global samp) {
  font-family: Monaco, Menlo, Consolas, Courier New, monospace;
}

:global code {
  color: #c00;
  white-space: nowrap;
  background-color: #fbfbfb;
  border-radius: 2px;
  padding: 2px 4px;
  font-size: 90%;
}

:global pre {
  word-break: break-all;
  word-wrap: break-word;
  color: #222;
  background-color: #fbfbfb;
  border: 1px solid #bbb;
  border-radius: 2px;
  margin: 0 0 10px;
  padding: 9.5px;
  font-size: 13px;
  line-height: 1.5;
  display: block;
}

:global pre code {
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: #0000;
  border-radius: 0;
  padding: 0;
}

:global .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

:is(:global .container, :global .container-fluid) {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

:global .container:before {
  content: " ";
  display: table;
}

:global .container:after {
  content: " ";
  display: table;
}

:global .container-fluid:before {
  content: " ";
  display: table;
}

:global .container-fluid:after {
  content: " ";
  display: table;
}

:global .container:after {
  clear: both;
}

:global .container-fluid:after {
  clear: both;
}

@media print {
  :is(:global .container, :global .container-fluid) {
    margin-left: 0;
    margin-right: 0;
  }
}

:global .row {
  margin-left: -15px;
  margin-right: -15px;
}

:global .row:before {
  content: " ";
  display: table;
}

:global .row:after {
  content: " ";
  display: table;
}

:global .row:after {
  clear: both;
}

:is(:global .col-xxs-12, :global .col-xs-12, :global .col-sm-12, :global .col-md-12, :global .col-lg-12, :global .col-xlg-12, :global .col-xxs-11, :global .col-xs-11, :global .col-sm-11, :global .col-md-11, :global .col-lg-11, :global .col-xlg-11, :global .col-xxs-10, :global .col-xs-10, :global .col-sm-10, :global .col-md-10, :global .col-lg-10, :global .col-xlg-10, :global .col-xxs-9, :global .col-xs-9, :global .col-sm-9, :global .col-md-9, :global .col-lg-9, :global .col-xlg-9, :global .col-xxs-8, :global .col-xs-8, :global .col-sm-8, :global .col-md-8, :global .col-lg-8, :global .col-xlg-8, :global .col-xxs-7, :global .col-xs-7, :global .col-sm-7, :global .col-md-7, :global .col-lg-7, :global .col-xlg-7, :global .col-xxs-6, :global .col-xs-6, :global .col-sm-6, :global .col-md-6, :global .col-lg-6, :global .col-xlg-6, :global .col-xxs-5, :global .col-xs-5, :global .col-sm-5, :global .col-md-5, :global .col-lg-5, :global .col-xlg-5, :global .col-xxs-4, :global .col-xs-4, :global .col-sm-4, :global .col-md-4, :global .col-lg-4, :global .col-xlg-4, :global .col-xxs-3, :global .col-xs-3, :global .col-sm-3, :global .col-md-3, :global .col-lg-3, :global .col-xlg-3, :global .col-xxs-2, :global .col-xs-2, :global .col-sm-2, :global .col-md-2, :global .col-lg-2, :global .col-xlg-2, :global .col-xxs-1, :global .col-xs-1, :global .col-sm-1, :global .col-md-1, :global .col-lg-1, :global .col-xlg-1) {
  box-sizing: border-box;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

:is(:global .col-xs-12, :global .col-xs-11, :global .col-xs-10, :global .col-xs-9, :global .col-xs-8, :global .col-xs-7, :global .col-xs-6, :global .col-xs-5, :global .col-xs-4, :global .col-xs-3, :global .col-xs-2, :global .col-xs-1) {
  float: left;
}

:global .col-xs-1 {
  width: 8.33333%;
}

:global .col-xs-2 {
  width: 16.6667%;
}

:global .col-xs-3 {
  width: 25%;
}

:global .col-xs-4 {
  width: 33.3333%;
}

:global .col-xs-5 {
  width: 41.6667%;
}

:global .col-xs-6 {
  width: 50%;
}

:global .col-xs-7 {
  width: 58.3333%;
}

:global .col-xs-8 {
  width: 66.6667%;
}

:global .col-xs-9 {
  width: 75%;
}

:global .col-xs-10 {
  width: 83.3333%;
}

:global .col-xs-11 {
  width: 91.6667%;
}

:global .col-xs-12 {
  width: 100%;
}

:global .col-xs-pull-0 {
  right: 0%;
}

:global .col-xs-pull-1 {
  right: 8.33333%;
}

:global .col-xs-pull-2 {
  right: 16.6667%;
}

:global .col-xs-pull-3 {
  right: 25%;
}

:global .col-xs-pull-4 {
  right: 33.3333%;
}

:global .col-xs-pull-5 {
  right: 41.6667%;
}

:global .col-xs-pull-6 {
  right: 50%;
}

:global .col-xs-pull-7 {
  right: 58.3333%;
}

:global .col-xs-pull-8 {
  right: 66.6667%;
}

:global .col-xs-pull-9 {
  right: 75%;
}

:global .col-xs-pull-10 {
  right: 83.3333%;
}

:global .col-xs-pull-11 {
  right: 91.6667%;
}

:global .col-xs-pull-12 {
  right: 100%;
}

:global .col-xs-push-0 {
  left: 0%;
}

:global .col-xs-push-1 {
  left: 8.33333%;
}

:global .col-xs-push-2 {
  left: 16.6667%;
}

:global .col-xs-push-3 {
  left: 25%;
}

:global .col-xs-push-4 {
  left: 33.3333%;
}

:global .col-xs-push-5 {
  left: 41.6667%;
}

:global .col-xs-push-6 {
  left: 50%;
}

:global .col-xs-push-7 {
  left: 58.3333%;
}

:global .col-xs-push-8 {
  left: 66.6667%;
}

:global .col-xs-push-9 {
  left: 75%;
}

:global .col-xs-push-10 {
  left: 83.3333%;
}

:global .col-xs-push-11 {
  left: 91.6667%;
}

:global .col-xs-push-12 {
  left: 100%;
}

:global .col-xs-offset-0 {
  margin-left: 0%;
}

:global .col-xs-offset-1 {
  margin-left: 8.33333%;
}

:global .col-xs-offset-2 {
  margin-left: 16.6667%;
}

:global .col-xs-offset-3 {
  margin-left: 25%;
}

:global .col-xs-offset-4 {
  margin-left: 33.3333%;
}

:global .col-xs-offset-5 {
  margin-left: 41.6667%;
}

:global .col-xs-offset-6 {
  margin-left: 50%;
}

:global .col-xs-offset-7 {
  margin-left: 58.3333%;
}

:global .col-xs-offset-8 {
  margin-left: 66.6667%;
}

:global .col-xs-offset-9 {
  margin-left: 75%;
}

:global .col-xs-offset-10 {
  margin-left: 83.3333%;
}

:global .col-xs-offset-11 {
  margin-left: 91.6667%;
}

:global .col-xs-offset-12 {
  margin-left: 100%;
}

@media (width >= 569px) {
  :global .container {
    width: 539px;
  }

  :is(:global .col-sm-12, :global .col-sm-11, :global .col-sm-10, :global .col-sm-9, :global .col-sm-8, :global .col-sm-7, :global .col-sm-6, :global .col-sm-5, :global .col-sm-4, :global .col-sm-3, :global .col-sm-2, :global .col-sm-1) {
    float: left;
  }

  :global .col-sm-1 {
    width: 8.33333%;
  }

  :global .col-sm-2 {
    width: 16.6667%;
  }

  :global .col-sm-3 {
    width: 25%;
  }

  :global .col-sm-4 {
    width: 33.3333%;
  }

  :global .col-sm-5 {
    width: 41.6667%;
  }

  :global .col-sm-6 {
    width: 50%;
  }

  :global .col-sm-7 {
    width: 58.3333%;
  }

  :global .col-sm-8 {
    width: 66.6667%;
  }

  :global .col-sm-9 {
    width: 75%;
  }

  :global .col-sm-10 {
    width: 83.3333%;
  }

  :global .col-sm-11 {
    width: 91.6667%;
  }

  :global .col-sm-12 {
    width: 100%;
  }

  :global .col-sm-pull-0 {
    right: 0%;
  }

  :global .col-sm-pull-1 {
    right: 8.33333%;
  }

  :global .col-sm-pull-2 {
    right: 16.6667%;
  }

  :global .col-sm-pull-3 {
    right: 25%;
  }

  :global .col-sm-pull-4 {
    right: 33.3333%;
  }

  :global .col-sm-pull-5 {
    right: 41.6667%;
  }

  :global .col-sm-pull-6 {
    right: 50%;
  }

  :global .col-sm-pull-7 {
    right: 58.3333%;
  }

  :global .col-sm-pull-8 {
    right: 66.6667%;
  }

  :global .col-sm-pull-9 {
    right: 75%;
  }

  :global .col-sm-pull-10 {
    right: 83.3333%;
  }

  :global .col-sm-pull-11 {
    right: 91.6667%;
  }

  :global .col-sm-pull-12 {
    right: 100%;
  }

  :global .col-sm-push-0 {
    left: 0%;
  }

  :global .col-sm-push-1 {
    left: 8.33333%;
  }

  :global .col-sm-push-2 {
    left: 16.6667%;
  }

  :global .col-sm-push-3 {
    left: 25%;
  }

  :global .col-sm-push-4 {
    left: 33.3333%;
  }

  :global .col-sm-push-5 {
    left: 41.6667%;
  }

  :global .col-sm-push-6 {
    left: 50%;
  }

  :global .col-sm-push-7 {
    left: 58.3333%;
  }

  :global .col-sm-push-8 {
    left: 66.6667%;
  }

  :global .col-sm-push-9 {
    left: 75%;
  }

  :global .col-sm-push-10 {
    left: 83.3333%;
  }

  :global .col-sm-push-11 {
    left: 91.6667%;
  }

  :global .col-sm-push-12 {
    left: 100%;
  }

  :global .col-sm-offset-0 {
    margin-left: 0%;
  }

  :global .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  :global .col-sm-offset-2 {
    margin-left: 16.6667%;
  }

  :global .col-sm-offset-3 {
    margin-left: 25%;
  }

  :global .col-sm-offset-4 {
    margin-left: 33.3333%;
  }

  :global .col-sm-offset-5 {
    margin-left: 41.6667%;
  }

  :global .col-sm-offset-6 {
    margin-left: 50%;
  }

  :global .col-sm-offset-7 {
    margin-left: 58.3333%;
  }

  :global .col-sm-offset-8 {
    margin-left: 66.6667%;
  }

  :global .col-sm-offset-9 {
    margin-left: 75%;
  }

  :global .col-sm-offset-10 {
    margin-left: 83.3333%;
  }

  :global .col-sm-offset-11 {
    margin-left: 91.6667%;
  }

  :global .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (width >= 769px) {
  :global .container {
    width: 739px;
  }

  :is(:global .col-md-12, :global .col-md-11, :global .col-md-10, :global .col-md-9, :global .col-md-8, :global .col-md-7, :global .col-md-6, :global .col-md-5, :global .col-md-4, :global .col-md-3, :global .col-md-2, :global .col-md-1) {
    float: left;
  }

  :global .col-md-1 {
    width: 8.33333%;
  }

  :global .col-md-2 {
    width: 16.6667%;
  }

  :global .col-md-3 {
    width: 25%;
  }

  :global .col-md-4 {
    width: 33.3333%;
  }

  :global .col-md-5 {
    width: 41.6667%;
  }

  :global .col-md-6 {
    width: 50%;
  }

  :global .col-md-7 {
    width: 58.3333%;
  }

  :global .col-md-8 {
    width: 66.6667%;
  }

  :global .col-md-9 {
    width: 75%;
  }

  :global .col-md-10 {
    width: 83.3333%;
  }

  :global .col-md-11 {
    width: 91.6667%;
  }

  :global .col-md-12 {
    width: 100%;
  }

  :global .col-md-pull-0 {
    right: 0%;
  }

  :global .col-md-pull-1 {
    right: 8.33333%;
  }

  :global .col-md-pull-2 {
    right: 16.6667%;
  }

  :global .col-md-pull-3 {
    right: 25%;
  }

  :global .col-md-pull-4 {
    right: 33.3333%;
  }

  :global .col-md-pull-5 {
    right: 41.6667%;
  }

  :global .col-md-pull-6 {
    right: 50%;
  }

  :global .col-md-pull-7 {
    right: 58.3333%;
  }

  :global .col-md-pull-8 {
    right: 66.6667%;
  }

  :global .col-md-pull-9 {
    right: 75%;
  }

  :global .col-md-pull-10 {
    right: 83.3333%;
  }

  :global .col-md-pull-11 {
    right: 91.6667%;
  }

  :global .col-md-pull-12 {
    right: 100%;
  }

  :global .col-md-push-0 {
    left: 0%;
  }

  :global .col-md-push-1 {
    left: 8.33333%;
  }

  :global .col-md-push-2 {
    left: 16.6667%;
  }

  :global .col-md-push-3 {
    left: 25%;
  }

  :global .col-md-push-4 {
    left: 33.3333%;
  }

  :global .col-md-push-5 {
    left: 41.6667%;
  }

  :global .col-md-push-6 {
    left: 50%;
  }

  :global .col-md-push-7 {
    left: 58.3333%;
  }

  :global .col-md-push-8 {
    left: 66.6667%;
  }

  :global .col-md-push-9 {
    left: 75%;
  }

  :global .col-md-push-10 {
    left: 83.3333%;
  }

  :global .col-md-push-11 {
    left: 91.6667%;
  }

  :global .col-md-push-12 {
    left: 100%;
  }

  :global .col-md-offset-0 {
    margin-left: 0%;
  }

  :global .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  :global .col-md-offset-2 {
    margin-left: 16.6667%;
  }

  :global .col-md-offset-3 {
    margin-left: 25%;
  }

  :global .col-md-offset-4 {
    margin-left: 33.3333%;
  }

  :global .col-md-offset-5 {
    margin-left: 41.6667%;
  }

  :global .col-md-offset-6 {
    margin-left: 50%;
  }

  :global .col-md-offset-7 {
    margin-left: 58.3333%;
  }

  :global .col-md-offset-8 {
    margin-left: 66.6667%;
  }

  :global .col-md-offset-9 {
    margin-left: 75%;
  }

  :global .col-md-offset-10 {
    margin-left: 83.3333%;
  }

  :global .col-md-offset-11 {
    margin-left: 91.6667%;
  }

  :global .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (width >= 1025px) {
  :global .container {
    width: 995px;
  }

  :is(:global .col-lg-12, :global .col-lg-11, :global .col-lg-10, :global .col-lg-9, :global .col-lg-8, :global .col-lg-7, :global .col-lg-6, :global .col-lg-5, :global .col-lg-4, :global .col-lg-3, :global .col-lg-2, :global .col-lg-1) {
    float: left;
  }

  :global .col-lg-1 {
    width: 8.33333%;
  }

  :global .col-lg-2 {
    width: 16.6667%;
  }

  :global .col-lg-3 {
    width: 25%;
  }

  :global .col-lg-4 {
    width: 33.3333%;
  }

  :global .col-lg-5 {
    width: 41.6667%;
  }

  :global .col-lg-6 {
    width: 50%;
  }

  :global .col-lg-7 {
    width: 58.3333%;
  }

  :global .col-lg-8 {
    width: 66.6667%;
  }

  :global .col-lg-9 {
    width: 75%;
  }

  :global .col-lg-10 {
    width: 83.3333%;
  }

  :global .col-lg-11 {
    width: 91.6667%;
  }

  :global .col-lg-12 {
    width: 100%;
  }

  :global .col-lg-pull-0 {
    right: 0%;
  }

  :global .col-lg-pull-1 {
    right: 8.33333%;
  }

  :global .col-lg-pull-2 {
    right: 16.6667%;
  }

  :global .col-lg-pull-3 {
    right: 25%;
  }

  :global .col-lg-pull-4 {
    right: 33.3333%;
  }

  :global .col-lg-pull-5 {
    right: 41.6667%;
  }

  :global .col-lg-pull-6 {
    right: 50%;
  }

  :global .col-lg-pull-7 {
    right: 58.3333%;
  }

  :global .col-lg-pull-8 {
    right: 66.6667%;
  }

  :global .col-lg-pull-9 {
    right: 75%;
  }

  :global .col-lg-pull-10 {
    right: 83.3333%;
  }

  :global .col-lg-pull-11 {
    right: 91.6667%;
  }

  :global .col-lg-pull-12 {
    right: 100%;
  }

  :global .col-lg-push-0 {
    left: 0%;
  }

  :global .col-lg-push-1 {
    left: 8.33333%;
  }

  :global .col-lg-push-2 {
    left: 16.6667%;
  }

  :global .col-lg-push-3 {
    left: 25%;
  }

  :global .col-lg-push-4 {
    left: 33.3333%;
  }

  :global .col-lg-push-5 {
    left: 41.6667%;
  }

  :global .col-lg-push-6 {
    left: 50%;
  }

  :global .col-lg-push-7 {
    left: 58.3333%;
  }

  :global .col-lg-push-8 {
    left: 66.6667%;
  }

  :global .col-lg-push-9 {
    left: 75%;
  }

  :global .col-lg-push-10 {
    left: 83.3333%;
  }

  :global .col-lg-push-11 {
    left: 91.6667%;
  }

  :global .col-lg-push-12 {
    left: 100%;
  }

  :global .col-lg-offset-0 {
    margin-left: 0%;
  }

  :global .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  :global .col-lg-offset-2 {
    margin-left: 16.6667%;
  }

  :global .col-lg-offset-3 {
    margin-left: 25%;
  }

  :global .col-lg-offset-4 {
    margin-left: 33.3333%;
  }

  :global .col-lg-offset-5 {
    margin-left: 41.6667%;
  }

  :global .col-lg-offset-6 {
    margin-left: 50%;
  }

  :global .col-lg-offset-7 {
    margin-left: 58.3333%;
  }

  :global .col-lg-offset-8 {
    margin-left: 66.6667%;
  }

  :global .col-lg-offset-9 {
    margin-left: 75%;
  }

  :global .col-lg-offset-10 {
    margin-left: 83.3333%;
  }

  :global .col-lg-offset-11 {
    margin-left: 91.6667%;
  }

  :global .col-lg-offset-12 {
    margin-left: 100%;
  }
}

:global .col-sm-gutter-left {
  padding-left: 6px;
}

:global .col-sm-gutter-right {
  padding-right: 6px;
}

:global table {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #0000;
  max-width: 100%;
}

:global th {
  text-align: left;
}

:global .table {
  width: 100%;
  margin-bottom: 20px;
}

:is(:global .table > thead > tr > th, :global .table > thead > tr > td, :global .table > tbody > tr > th, :global .table > tbody > tr > td, :global .table > tfoot > tr > th, :global .table > tfoot > tr > td) {
  vertical-align: top;
  border-top: 1px solid #ddd;
  padding: 9px;
  line-height: 1.5;
}

:global .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

:global .table > caption + thead > tr:first-child > th {
  border-top: 0;
}

:global .table > caption + thead > tr:first-child > td {
  border-top: 0;
}

:global .table > colgroup + thead > tr:first-child > th {
  border-top: 0;
}

:global .table > colgroup + thead > tr:first-child > td {
  border-top: 0;
}

:global .table > thead:first-child > tr:first-child > th {
  border-top: 0;
}

:global .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

:global .table > tbody + tbody {
  border-top: 2px solid #ddd;
}

:global .table .table {
  background-color: #fff;
}

:is(:global .table-condensed > thead > tr > th, :global .table-condensed > thead > tr > td, :global .table-condensed > tbody > tr > th, :global .table-condensed > tbody > tr > td, :global .table-condensed > tfoot > tr > th, :global .table-condensed > tfoot > tr > td) {
  padding: 5px;
}

:global .table-bordered {
  border: 1px solid #ddd;
}

:is(:global .table-bordered > thead > tr > th, :global .table-bordered > thead > tr > td, :global .table-bordered > tbody > tr > th, :global .table-bordered > tbody > tr > td, :global .table-bordered > tfoot > tr > th, :global .table-bordered > tfoot > tr > td) {
  border: 1px solid #ddd;
}

:is(:global .table-bordered > thead > tr > th, :global .table-bordered > thead > tr > td) {
  border-bottom-width: 2px;
}

:is(:global .table-borderless > thead > tr > th, :global .table-borderless > thead > tr > td, :global .table-borderless > tbody > tr > th, :global .table-borderless > tbody > tr > td, :global .table-borderless > tfoot > tr > th, :global .table-borderless > tfoot > tr > td) {
  border: 0;
}

:global .table-no-padding {
  margin-bottom: 0 !important;
}

:is(:global .table-no-padding > thead > tr > th, :global .table-no-padding > thead > tr > td, :global .table-no-padding > tbody > tr > th, :global .table-no-padding > tbody > tr > td, :global .table-no-padding > tfoot > tr > th, :global .table-no-padding > tfoot > tr > td) {
  vertical-align: bottom;
  padding: 0 !important;
}

:is(:global .table-no-left-padd > thead > tr > th:first-child, :global .table-no-left-padd > thead > tr > td:first-child, :global .table-no-left-padd > tbody > tr > th:first-child, :global .table-no-left-padd > tbody > tr > td:first-child, :global .table-no-left-padd > tfoot > tr > th:first-child, :global .table-no-left-padd > tfoot > tr > td:first-child) {
  padding-left: 0 !important;
}

:is(:global .table-striped > tbody > tr:nth-child(odd) > td, :global .table-striped > tbody > tr:nth-child(odd) > th), :is(:global .table-hover > tbody > tr:hover > td, :global .table-hover > tbody > tr:hover > th) {
  background-color: #f3f3f3;
}

:global table col[class*="col-"] {
  float: none;
  display: table-column;
}

:is(:global table td[class*="col-"], :global table th[class*="col-"]) {
  float: none;
  display: table-cell;
}

:is(:global .table > thead > tr > td.active, :global .table > thead > tr > th.active, :global .table > thead > tr.active > td, :global .table > thead > tr.active > th, :global .table > tbody > tr > td.active, :global .table > tbody > tr > th.active, :global .table > tbody > tr.active > td, :global .table > tbody > tr.active > th, :global .table > tfoot > tr > td.active, :global .table > tfoot > tr > th.active, :global .table > tfoot > tr.active > td, :global .table > tfoot > tr.active > th) {
  background-color: #f3f3f3;
}

:is(:global .table > thead > tr > td.success, :global .table > thead > tr > th.success, :global .table > thead > tr.success > td, :global .table > thead > tr.success > th, :global .table > tbody > tr > td.success, :global .table > tbody > tr > th.success, :global .table > tbody > tr.success > td, :global .table > tbody > tr.success > th, :global .table > tfoot > tr > td.success, :global .table > tfoot > tr > th.success, :global .table > tfoot > tr.success > td, :global .table > tfoot > tr.success > th) {
  background-color: #b8e7c8;
}

:is(:global .table-hover > tbody > tr > td.success:hover, :global .table-hover > tbody > tr > th.success:hover, :global .table-hover > tbody > tr.success:hover > td, :global .table-hover > tbody > tr.success:hover > th) {
  background-color: #a5e1b9;
}

:is(:global .table > thead > tr > td.danger, :global .table > thead > tr > th.danger, :global .table > thead > tr.danger > td, :global .table > thead > tr.danger > th, :global .table > tbody > tr > td.danger, :global .table > tbody > tr > th.danger, :global .table > tbody > tr.danger > td, :global .table > tbody > tr.danger > th, :global .table > tfoot > tr > td.danger, :global .table > tfoot > tr > th.danger, :global .table > tfoot > tr.danger > td, :global .table > tfoot > tr.danger > th) {
  background-color: #fbfbfb;
}

:is(:global .table-hover > tbody > tr > td.danger:hover, :global .table-hover > tbody > tr > th.danger:hover, :global .table-hover > tbody > tr.danger:hover > td, :global .table-hover > tbody > tr.danger:hover > th) {
  background-color: #eee;
}

:is(:global .table > thead > tr > td.warning, :global .table > thead > tr > th.warning, :global .table > thead > tr.warning > td, :global .table > thead > tr.warning > th, :global .table > tbody > tr > td.warning, :global .table > tbody > tr > th.warning, :global .table > tbody > tr.warning > td, :global .table > tbody > tr.warning > th, :global .table > tfoot > tr > td.warning, :global .table > tfoot > tr > th.warning, :global .table > tfoot > tr.warning > td, :global .table > tfoot > tr.warning > th) {
  background-color: #f3f3f3;
}

:is(:global .table-hover > tbody > tr > td.warning:hover, :global .table-hover > tbody > tr > th.warning:hover, :global .table-hover > tbody > tr.warning:hover > td, :global .table-hover > tbody > tr.warning:hover > th) {
  background-color: #e6e6e6;
}

@media (width <= 768px) {
  :global .table-responsive {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #ddd;
    width: 100%;
    margin-bottom: 15px;
    overflow: scroll hidden;
  }

  :global .table-responsive > .table {
    margin-bottom: 0;
  }

  :is(:global .table-responsive > .table > thead > tr > th, :global .table-responsive > .table > thead > tr > td, :global .table-responsive > .table > tbody > tr > th, :global .table-responsive > .table > tbody > tr > td, :global .table-responsive > .table > tfoot > tr > th, :global .table-responsive > .table > tfoot > tr > td) {
    white-space: nowrap;
  }

  :global .table-responsive > .table-bordered {
    border: 0;
  }

  :is(:global .table-responsive > .table-bordered > thead > tr > th:first-child, :global .table-responsive > .table-bordered > thead > tr > td:first-child, :global .table-responsive > .table-bordered > tbody > tr > th:first-child, :global .table-responsive > .table-bordered > tbody > tr > td:first-child, :global .table-responsive > .table-bordered > tfoot > tr > th:first-child, :global .table-responsive > .table-bordered > tfoot > tr > td:first-child) {
    border-left: 0;
  }

  :is(:global .table-responsive > .table-bordered > thead > tr > th:last-child, :global .table-responsive > .table-bordered > thead > tr > td:last-child, :global .table-responsive > .table-bordered > tbody > tr > th:last-child, :global .table-responsive > .table-bordered > tbody > tr > td:last-child, :global .table-responsive > .table-bordered > tfoot > tr > th:last-child, :global .table-responsive > .table-bordered > tfoot > tr > td:last-child) {
    border-right: 0;
  }

  :is(:global .table-responsive > .table-bordered > tbody > tr:last-child > th, :global .table-responsive > .table-bordered > tbody > tr:last-child > td, :global .table-responsive > .table-bordered > tfoot > tr:last-child > th, :global .table-responsive > .table-bordered > tfoot > tr:last-child > td) {
    border-bottom: 0;
  }
}

:global fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

:global legend {
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  line-height: inherit;
  color: #222;
  border: 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 0;
  display: block;
}

:global label {
  margin-bottom: 5px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
  display: inline-block;
}

:global input[type="search"] {
  box-sizing: border-box;
}

:is(:global input[type="radio"], :global input[type="checkbox"]) {
  margin: 1px 0 0;
  line-height: normal;
}

:global input[type="file"] {
  display: block;
}

:is(:global select[multiple], :global select[size]) {
  height: auto;
}

:is(:global input[type="file"]:focus, :global input[type="radio"]:focus, :global input[type="checkbox"]:focus) {
  outline: thin dotted #222;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

:global input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

:global input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

:global output {
  color: #222;
  vertical-align: middle;
  padding-top: 7px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  display: block;
}

:global select {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global textarea {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="text"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="password"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="datetime"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="datetime-local"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="date"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="month"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="time"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="week"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="number"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="email"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="url"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="search"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="tel"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global input[type="color"] {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global .form-control {
  color: #222;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #bbb;
  border-radius: 2px;
  width: 100%;
  height: 33px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: inset 0 1px 1px #000000b3;
}

:global select:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global textarea:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="text"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="password"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="datetime"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="datetime-local"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="date"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="month"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="time"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="week"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="number"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="email"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="url"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="search"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="tel"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global input[type="color"]:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global .form-control:focus {
  border-color: #d8ecf5;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #d8ecf599;
}

:global select:-moz-placeholder {
  color: #888;
}

:global textarea:-moz-placeholder {
  color: #888;
}

:global input[type="text"]:-moz-placeholder {
  color: #888;
}

:global input[type="password"]:-moz-placeholder {
  color: #888;
}

:global input[type="datetime"]:-moz-placeholder {
  color: #888;
}

:global input[type="datetime-local"]:-moz-placeholder {
  color: #888;
}

:global input[type="date"]:-moz-placeholder {
  color: #888;
}

:global input[type="month"]:-moz-placeholder {
  color: #888;
}

:global input[type="time"]:-moz-placeholder {
  color: #888;
}

:global input[type="week"]:-moz-placeholder {
  color: #888;
}

:global input[type="number"]:-moz-placeholder {
  color: #888;
}

:global input[type="email"]:-moz-placeholder {
  color: #888;
}

:global input[type="url"]:-moz-placeholder {
  color: #888;
}

:global input[type="search"]:-moz-placeholder {
  color: #888;
}

:global input[type="tel"]:-moz-placeholder {
  color: #888;
}

:global input[type="color"]:-moz-placeholder {
  color: #888;
}

:global .form-control:-moz-placeholder {
  color: #888;
}

:global select::-moz-placeholder {
  color: #888;
}

:global textarea::-moz-placeholder {
  color: #888;
}

:global input[type="text"]::-moz-placeholder {
  color: #888;
}

:global input[type="password"]::-moz-placeholder {
  color: #888;
}

:global input[type="datetime"]::-moz-placeholder {
  color: #888;
}

:global input[type="datetime-local"]::-moz-placeholder {
  color: #888;
}

:global input[type="date"]::-moz-placeholder {
  color: #888;
}

:global input[type="month"]::-moz-placeholder {
  color: #888;
}

:global input[type="time"]::-moz-placeholder {
  color: #888;
}

:global input[type="week"]::-moz-placeholder {
  color: #888;
}

:global input[type="number"]::-moz-placeholder {
  color: #888;
}

:global input[type="email"]::-moz-placeholder {
  color: #888;
}

:global input[type="url"]::-moz-placeholder {
  color: #888;
}

:global input[type="search"]::-moz-placeholder {
  color: #888;
}

:global input[type="tel"]::-moz-placeholder {
  color: #888;
}

:global input[type="color"]::-moz-placeholder {
  color: #888;
}

:global .form-control::-moz-placeholder {
  color: #888;
}

:global select:-ms-input-placeholder {
  color: #888;
}

:global textarea:-ms-input-placeholder {
  color: #888;
}

:global input[type="text"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="password"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="datetime"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="datetime-local"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="date"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="month"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="time"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="week"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="number"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="email"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="url"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="search"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="tel"]:-ms-input-placeholder {
  color: #888;
}

:global input[type="color"]:-ms-input-placeholder {
  color: #888;
}

:global .form-control:-ms-input-placeholder {
  color: #888;
}

:global select::-webkit-input-placeholder {
  color: #888;
}

:global textarea::-webkit-input-placeholder {
  color: #888;
}

:global input[type="text"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="password"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="datetime"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="datetime-local"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="date"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="month"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="time"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="week"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="number"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="email"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="url"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="search"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="tel"]::-webkit-input-placeholder {
  color: #888;
}

:global input[type="color"]::-webkit-input-placeholder {
  color: #888;
}

:global .form-control::-webkit-input-placeholder {
  color: #888;
}

:global select[disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global select[readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global select {
  cursor: not-allowed;
  background-color: #ddd;
}

:global textarea[disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global textarea[readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global textarea {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="text"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="text"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="text"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="password"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="password"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="password"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="datetime"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="datetime"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="datetime"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="datetime-local"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="datetime-local"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="datetime-local"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="date"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="date"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="date"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="month"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="month"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="month"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="time"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="time"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="time"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="week"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="week"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="week"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="number"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="number"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="number"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="email"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="email"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="email"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="url"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="url"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="url"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="search"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="search"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="search"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="tel"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="tel"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="tel"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="color"][disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global input[type="color"][readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global input[type="color"] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global .form-control[disabled] {
  cursor: not-allowed;
  background-color: #ddd;
}

:global .form-control[readonly] {
  cursor: not-allowed;
  background-color: #ddd;
}

fieldset[disabled] :global .form-control {
  cursor: not-allowed;
  background-color: #ddd;
}

:global select.invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global textarea.invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="text"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="password"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="datetime"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="datetime-local"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="date"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="month"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="time"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="week"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="number"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="email"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="url"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="search"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="tel"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global input[type="color"].invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global .form-control.invalid {
  color: #c00;
  border: 1px solid #c00;
}

:global select.valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global textarea.valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="text"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="password"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="datetime"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="datetime-local"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="date"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="month"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="time"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="week"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="number"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="email"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="url"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="search"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="tel"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global input[type="color"].valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:global .form-control.valid {
  color: #66a559;
  border: 1px solid #66a559;
}

:is(:global label, :global input, :global button, :global select, :global textarea) {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

:is(:global .radio.inline, :global .checkbox.inline) {
  vertical-align: middle;
  margin-bottom: 0;
  padding-top: 5px;
  display: inline-block;
}

:global textarea {
  height: auto;
}

:global .form-group {
  margin-bottom: 15px;
}

:is(:global .radio, :global .checkbox) {
  vertical-align: middle;
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  display: block;
}

:is(:global .radio label, :global .checkbox label) {
  cursor: pointer;
  margin-bottom: 0;
  display: inline;
}

:is(:global .radio label.disabled, :global .radio label[disabled], :global .checkbox label.disabled, :global .checkbox label[disabled]) {
  cursor: not-allowed;
}

:is(:global .radio input[type="radio"], :global .radio-inline input[type="radio"], :global .checkbox input[type="checkbox"], :global .checkbox-inline input[type="checkbox"]) {
  float: left;
  margin-left: -20px;
}

:is(:global .radio + .radio, :global .checkbox + .checkbox) {
  margin-top: -5px;
}

:is(:global .radio-inline, :global .checkbox-inline) {
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 20px;
  display: inline-block;
}

:is(:global .radio-inline + .radio-inline, :global .checkbox-inline + .checkbox-inline) {
  margin-top: 0;
  margin-left: 10px;
}

:global input[type="radio"][disabled] {
  cursor: not-allowed;
}

fieldset[disabled] :global input[type="radio"] {
  cursor: not-allowed;
}

:global input[type="checkbox"][disabled] {
  cursor: not-allowed;
}

fieldset[disabled] :global input[type="checkbox"] {
  cursor: not-allowed;
}

:global .radio[disabled] {
  cursor: not-allowed;
}

fieldset[disabled] :global .radio {
  cursor: not-allowed;
}

:global .radio-inline[disabled] {
  cursor: not-allowed;
}

fieldset[disabled] :global .radio-inline {
  cursor: not-allowed;
}

:global .checkbox[disabled] {
  cursor: not-allowed;
}

fieldset[disabled] :global .checkbox {
  cursor: not-allowed;
}

:global .checkbox-inline[disabled] {
  cursor: not-allowed;
}

fieldset[disabled] :global .checkbox-inline {
  cursor: not-allowed;
}

:global .input-sm {
  border-radius: 2px;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

:global .input-group-sm > .form-control {
  border-radius: 2px;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

:global .input-group-sm > .input-group-addon {
  border-radius: 2px;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

:global .input-group-sm > .input-group-btn > .btn {
  border-radius: 2px;
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

:global select.input-sm {
  height: 30px;
  line-height: 30px;
}

:global .input-group-sm > select.form-control {
  height: 30px;
  line-height: 30px;
}

:global .input-group-sm > select.input-group-addon {
  height: 30px;
  line-height: 30px;
}

:global .input-group-sm > .input-group-btn > select.btn {
  height: 30px;
  line-height: 30px;
}

:global textarea.input-sm {
  height: auto;
}

:global .input-group-sm > textarea.form-control {
  height: auto;
}

:global .input-group-sm > textarea.input-group-addon {
  height: auto;
}

:global .input-group-sm > .input-group-btn > textarea.btn {
  height: auto;
}

:global .input-lg {
  border-radius: 2px;
  height: 50px;
  padding: 12px 20px;
  font-size: 18px;
  line-height: 1.33;
}

:global .input-group-lg > .form-control {
  border-radius: 2px;
  height: 50px;
  padding: 12px 20px;
  font-size: 18px;
  line-height: 1.33;
}

:global .input-group-lg > .input-group-addon {
  border-radius: 2px;
  height: 50px;
  padding: 12px 20px;
  font-size: 18px;
  line-height: 1.33;
}

:global .input-group-lg > .input-group-btn > .btn {
  border-radius: 2px;
  height: 50px;
  padding: 12px 20px;
  font-size: 18px;
  line-height: 1.33;
}

:global select.input-lg {
  height: 50px;
  line-height: 50px;
}

:global .input-group-lg > select.form-control {
  height: 50px;
  line-height: 50px;
}

:global .input-group-lg > select.input-group-addon {
  height: 50px;
  line-height: 50px;
}

:global .input-group-lg > .input-group-btn > select.btn {
  height: 50px;
  line-height: 50px;
}

:global textarea.input-lg {
  height: auto;
}

:global .input-group-lg > textarea.form-control {
  height: auto;
}

:global .input-group-lg > textarea.input-group-addon {
  height: auto;
}

:global .input-group-lg > .input-group-btn > textarea.btn {
  height: auto;
}

:is(:global .has-warning .help-block, :global .has-warning .control-label, :global .has-warning .radio, :global .has-warning .checkbox, :global .has-warning .radio-inline, :global .has-warning .checkbox-inline) {
  color: #ffc100;
}

:global .has-warning .form-control {
  border-color: #ffc100;
  box-shadow: inset 0 1px 1px #22222213;
}

:global .has-warning .form-control:focus {
  border-color: #cc9a00;
  box-shadow: inset 0 1px 1px #22222213, 0 0 6px #ffda66;
}

:global .has-warning .input-group-addon {
  color: #ffc100;
  background-color: #f3f3f3;
  border-color: #ffc100;
}

:is(:global .has-error .help-block, :global .has-error .control-label, :global .has-error .radio, :global .has-error .checkbox, :global .has-error .radio-inline, :global .has-error .checkbox-inline) {
  color: #c00;
}

:global .has-error .form-control {
  border-color: #c00;
  box-shadow: inset 0 1px 1px #22222213;
}

:global .has-error .form-control:focus {
  border-color: #900;
  box-shadow: inset 0 1px 1px #22222213, 0 0 6px #f33;
}

:global .has-error .input-group-addon {
  color: #c00;
  background-color: #fbfbfb;
  border-color: #c00;
}

:is(:global .has-success .help-block, :global .has-success .control-label, :global .has-success .radio, :global .has-success .checkbox, :global .has-success .radio-inline, :global .has-success .checkbox-inline) {
  color: #66a559;
}

:global .has-success .form-control {
  border-color: #66a559;
  box-shadow: inset 0 1px 1px #22222213;
}

:global .has-success .form-control:focus {
  border-color: #528447;
  box-shadow: inset 0 1px 1px #22222213, 0 0 6px #a3c99b;
}

:global .has-success .input-group-addon {
  color: #66a559;
  background-color: #b8e7c8;
  border-color: #66a559;
}

:global input[type="search"] {
  border-radius: 15px;
}

:global .form-search {
  border-radius: 15px;
}

:global .search-query {
  border-radius: 15px;
}

:global .form-control-static {
  margin-bottom: 0;
}

:global .help-block {
  color: #666;
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
}

@media (width >= 769px) {
  :is(:global .form-inline .form-group, :global .navbar-form .form-group) {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  :is(:global .form-inline .form-control, :global .navbar-form .form-control) {
    display: inline-block;
  }

  :is(:global .form-inline .with-marg, :global .navbar-form .with-marg) {
    margin-left: 5px;
  }

  :is(:global .form-inline .with-marg:first-child, :global .navbar-form .with-marg:first-child) {
    margin-left: 0;
  }

  :is(:global .form-inline .radio, :global .navbar-form .radio, :global .form-inline .checkbox, :global .navbar-form .checkbox) {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    display: inline-block;
  }

  :is(:global .form-inline .radio input[type="radio"], :global .navbar-form .radio input[type="radio"], :global .form-inline .checkbox input[type="checkbox"], :global .navbar-form .checkbox input[type="checkbox"]) {
    float: none;
    margin-left: 0;
  }

  :is(:global .form-inline.with-marg .radio, :global .with-marg.navbar-form .radio, :global .form-inline.with-marg .select, :global .with-marg.navbar-form .select, :global .form-inline.with-marg .form-group, :global .with-marg.navbar-form .form-group, :global .form-inline.with-marg .form-control, :global .with-marg.navbar-form .form-control) {
    margin: 5px;
  }

  :is(:global .form-inline.with-marg .radio:first-child, :global .with-marg.navbar-form .radio:first-child, :global .form-inline.with-marg .select:first-child, :global .with-marg.navbar-form .select:first-child, :global .form-inline.with-marg .form-group:first-child, :global .with-marg.navbar-form .form-group:first-child, :global .form-inline.with-marg .form-control:first-child, :global .with-marg.navbar-form .form-control:first-child) {
    margin-left: 0;
  }
}

@media (width >= 569px) {
  :global .form-inline-phone .form-group {
    vertical-align: middle;
    margin-bottom: 0;
    display: inline-block;
  }

  :global .form-inline-phone .form-control {
    display: inline-block;
  }

  :global .form-inline-phone .with-marg {
    margin-left: 5px;
  }

  :global .form-inline-phone .with-marg:first-child {
    margin-left: 0;
  }

  :is(:global .form-inline-phone .radio, :global .form-inline-phone .checkbox) {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    display: inline-block;
  }

  :is(:global .form-inline-phone .radio input[type="radio"], :global .form-inline-phone .checkbox input[type="checkbox"]) {
    float: none;
    margin-left: 0;
  }

  :is(:global .form-inline-phone.with-marg .radio, :global .form-inline-phone.with-marg .select, :global .form-inline-phone.with-marg .form-group, :global .form-inline-phone.with-marg .form-control) {
    margin: 5px;
  }

  :is(:global .form-inline-phone.with-marg .radio:first-child, :global .form-inline-phone.with-marg .select:first-child, :global .form-inline-phone.with-marg .form-group:first-child, :global .form-inline-phone.with-marg .form-control:first-child) {
    margin-left: 0;
  }
}

:global .form-inline-all .form-group {
  vertical-align: middle;
  margin-bottom: 0;
  display: inline-block;
}

:global .form-inline-all .form-control {
  display: inline-block;
}

:global .form-inline-all .with-marg {
  margin-left: 5px;
}

:global .form-inline-all .with-marg:first-child {
  margin-left: 0;
}

:is(:global .form-inline-all .radio, :global .form-inline-all .checkbox) {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  display: inline-block;
}

:is(:global .form-inline-all .radio input[type="radio"], :global .form-inline-all .checkbox input[type="checkbox"]) {
  float: none;
  margin-left: 0;
}

:is(:global .form-inline-all.with-marg .radio, :global .form-inline-all.with-marg .select, :global .form-inline-all.with-marg .form-group, :global .form-inline-all.with-marg .form-control) {
  margin: 5px;
}

:is(:global .form-inline-all.with-marg .radio:first-child, :global .form-inline-all.with-marg .select:first-child, :global .form-inline-all.with-marg .form-group:first-child, :global .form-inline-all.with-marg .form-control:first-child) {
  margin-left: 0;
}

:is(:global .form-horizontal .control-label, :global .form-horizontal .radio, :global .form-horizontal .checkbox, :global .form-horizontal .radio-inline, :global .form-horizontal .checkbox-inline) {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

:global .form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

:global .form-horizontal .form-group:before {
  content: " ";
  display: table;
}

:global .form-horizontal .form-group:after {
  content: " ";
  display: table;
}

:global .form-horizontal .form-group:after {
  clear: both;
}

:global .form-horizontal .form-control-static {
  padding-top: 7px;
}

:global .form-horizontal.form-horizontal-default .form-group > label:first-child {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media (width >= 769px) {
  :global .form-horizontal.form-horizontal-default .form-group > label:first-child {
    float: left;
    width: 33.3333%;
  }
}

:global .form-horizontal.form-horizontal-default .form-group > :nth-child(2) {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media (width >= 769px) {
  :global .form-horizontal.form-horizontal-default .form-group > :nth-child(2) {
    float: left;
    width: 66.6667%;
  }

  :global .form-horizontal .control-label {
    text-align: right;
  }
}

:global select {
  padding-right: 3px !important;
}

:global .field-maxw-xs {
  max-width: 35px;
}

:global .field-maxw-sm {
  max-width: 90px;
}

:global .field-maxw-md {
  max-width: 150px;
}

:global .field-maxw-lg {
  max-width: 210px;
}

:global .field-maxw-xlg {
  max-width: 270px;
}

:global .field-maxw-xxlg {
  max-width: 530px;
}

:global .input-maxw-xs {
  width: 100%;
  max-width: 35px;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

:global .input-maxw-sm {
  width: 100%;
  max-width: 90px;
}

:global .input-maxw-md {
  width: 100%;
  max-width: 150px;
}

:global .input-maxw-lg {
  width: 100%;
  max-width: 210px;
}

:global .input-maxw-xlg {
  width: 100%;
  max-width: 270px;
}

:global .input-maxw-xxlg {
  width: 100%;
  max-width: 530px;
}

:global .input-w-xs {
  width: 35px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

:global .input-w-sm {
  width: 90px !important;
}

:global .input-w-md {
  width: 150px !important;
}

:global .input-w-lg {
  width: 210px !important;
}

:global .input-w-auto {
  width: auto !important;
}

:global .btn {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #0000;
  border-radius: 2px;
  margin-bottom: 0;
  padding: 6px 12px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  display: inline-block;
  box-shadow: 0 1px 2px #ddd;
}

:global .btn:focus {
  outline: thin dotted #222;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

:is(:global .btn:hover, :global .btn:focus) {
  color: #222;
  text-decoration: none;
}

:is(:global .btn:active, :global .btn.active) {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px #22222220;
}

:global .btn.disabled {
  cursor: not-allowed;
  text-shadow: none;
  opacity: .65;
  box-shadow: none;
}

:global .btn[disabled] {
  cursor: not-allowed;
  text-shadow: none;
  opacity: .65;
  box-shadow: none;
}

fieldset[disabled] :global .btn {
  cursor: not-allowed;
  text-shadow: none;
  opacity: .65;
  box-shadow: none;
}

:global .btn-default {
  color: #222;
  background-color: #f3f3f3;
  border-color: #ddd;
}

:is(:global .btn-default:hover, :global .btn-default:focus, :global .btn-default:active, :global .btn-default.active) {
  color: #222;
  background-color: #dfdfdf;
  border-color: #bebebe;
}

.open :global .btn-default.dropdown-toggle {
  color: #222;
  background-color: #dfdfdf;
  border-color: #bebebe;
}

:is(:global .btn-default:active, :global .btn-default.active) {
  background-image: none;
}

.open :global .btn-default.dropdown-toggle {
  background-image: none;
}

:global .btn-default.disabled {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-default.disabled:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-default.disabled:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-default.disabled:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-default.disabled.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-default[disabled] {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-default[disabled]:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-default[disabled]:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-default[disabled]:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-default[disabled].active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-default {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-default:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-default:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-default:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-default.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-primary {
  color: #fff;
  background-color: #436b93;
  border-color: #204664;
}

:is(:global .btn-primary:hover, :global .btn-primary:focus, :global .btn-primary:active, :global .btn-primary.active) {
  color: #fff;
  background-color: #365777;
  border-color: #112636;
}

.open :global .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #365777;
  border-color: #112636;
}

:is(:global .btn-primary:active, :global .btn-primary.active) {
  background-image: none;
}

.open :global .btn-primary.dropdown-toggle {
  background-image: none;
}

:global .btn-primary.disabled {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-primary.disabled:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-primary.disabled:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-primary.disabled:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-primary.disabled.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-primary[disabled] {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-primary[disabled]:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-primary[disabled]:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-primary[disabled]:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-primary[disabled].active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-primary {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-primary:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-primary:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-primary:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-primary.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-warning {
  color: #fff;
  background-color: #f07f04;
  border-color: #f07f04;
}

:is(:global .btn-warning:hover, :global .btn-warning:focus, :global .btn-warning:active, :global .btn-warning.active) {
  color: #fff;
  background-color: #c86a03;
  border-color: #b45f03;
}

.open :global .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #c86a03;
  border-color: #b45f03;
}

:is(:global .btn-warning:active, :global .btn-warning.active) {
  background-image: none;
}

.open :global .btn-warning.dropdown-toggle {
  background-image: none;
}

:global .btn-warning.disabled {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-warning.disabled:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-warning.disabled:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-warning.disabled:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-warning.disabled.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-warning[disabled] {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-warning[disabled]:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-warning[disabled]:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-warning[disabled]:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-warning[disabled].active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-warning {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-warning:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-warning:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-warning:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-warning.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-danger {
  color: #fff;
  background-color: #c00;
  border-color: #c00;
}

:is(:global .btn-danger:hover, :global .btn-danger:focus, :global .btn-danger:active, :global .btn-danger.active) {
  color: #fff;
  background-color: #a30000;
  border-color: #8f0000;
}

.open :global .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #a30000;
  border-color: #8f0000;
}

:is(:global .btn-danger:active, :global .btn-danger.active) {
  background-image: none;
}

.open :global .btn-danger.dropdown-toggle {
  background-image: none;
}

:global .btn-danger.disabled {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-danger.disabled:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-danger.disabled:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-danger.disabled:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-danger.disabled.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-danger[disabled] {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-danger[disabled]:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-danger[disabled]:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-danger[disabled]:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-danger[disabled].active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-danger {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-danger:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-danger:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-danger:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-danger.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-success {
  color: #fff;
  background-color: #66a559;
  border-color: #66a559;
}

:is(:global .btn-success:hover, :global .btn-success:focus, :global .btn-success:active, :global .btn-success.active) {
  color: #fff;
  background-color: #568a4b;
  border-color: #4d7d44;
}

.open :global .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #568a4b;
  border-color: #4d7d44;
}

:is(:global .btn-success:active, :global .btn-success.active) {
  background-image: none;
}

.open :global .btn-success.dropdown-toggle {
  background-image: none;
}

:global .btn-success.disabled {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-success.disabled:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-success.disabled:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-success.disabled:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-success.disabled.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-success[disabled] {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-success[disabled]:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-success[disabled]:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-success[disabled]:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-success[disabled].active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-success {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-success:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-success:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-success:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-success.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-info {
  color: #fff;
  background-color: #436b93;
  border-color: #436b93;
}

:is(:global .btn-info:hover, :global .btn-info:focus, :global .btn-info:active, :global .btn-info.active) {
  color: #fff;
  background-color: #365777;
  border-color: #304c69;
}

.open :global .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #365777;
  border-color: #304c69;
}

:is(:global .btn-info:active, :global .btn-info.active) {
  background-image: none;
}

.open :global .btn-info.dropdown-toggle {
  background-image: none;
}

:global .btn-info.disabled {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-info.disabled:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-info.disabled:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-info.disabled:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-info.disabled.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-info[disabled] {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-info[disabled]:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-info[disabled]:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-info[disabled]:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:global .btn-info[disabled].active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-info {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-info:hover {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-info:focus {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-info:active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

fieldset[disabled] :global .btn-info.active {
  color: #888;
  background-color: #ddd;
  border-color: #ddd;
}

:is(:global .btn-link, :global .dibs-btn-link) {
  color: #436b93;
  cursor: pointer;
  box-shadow: none;
  text-transform: none;
  border-radius: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
}

:global .btn-link {
  box-shadow: none;
  background-color: #0000;
}

:global .btn-link:active {
  box-shadow: none;
  background-color: #0000;
}

:global .btn-link[disabled] {
  box-shadow: none;
  background-color: #0000;
}

fieldset[disabled] :global .btn-link {
  box-shadow: none;
  background-color: #0000;
}

:global .dibs-btn-link {
  box-shadow: none;
  background-color: #0000;
}

:global .dibs-btn-link:active {
  box-shadow: none;
  background-color: #0000;
}

:global .dibs-btn-link[disabled] {
  box-shadow: none;
  background-color: #0000;
}

fieldset[disabled] :global .dibs-btn-link {
  box-shadow: none;
  background-color: #0000;
}

:global .btn-link {
  border-color: #0000;
}

:global .btn-link:hover {
  border-color: #0000;
}

:global .btn-link:focus {
  border-color: #0000;
}

:global .btn-link:active {
  border-color: #0000;
}

:global .dibs-btn-link {
  border-color: #0000;
}

:global .dibs-btn-link:hover {
  border-color: #0000;
}

:global .dibs-btn-link:focus {
  border-color: #0000;
}

:global .dibs-btn-link:active {
  border-color: #0000;
}

:is(:global .btn-link:hover, :global .btn-link:focus, :global .dibs-btn-link:hover, :global .dibs-btn-link:focus) {
  color: #222;
  background-color: #0000;
  text-decoration: underline;
}

:global .btn-link.disabled {
  color: #888;
  text-decoration: none;
}

:global .btn-link[disabled] {
  color: #888;
  text-decoration: none;
}

fieldset[disabled] :global .btn-link {
  color: #888;
  text-decoration: none;
}

:global .dibs-btn-link.disabled {
  color: #888;
  text-decoration: none;
}

:global .dibs-btn-link[disabled] {
  color: #888;
  text-decoration: none;
}

fieldset[disabled] :global .dibs-btn-link {
  color: #888;
  text-decoration: none;
}

:global .btn-link.disabled:hover {
  color: #888;
  text-decoration: none;
}

:global .btn-link.disabled:focus {
  color: #888;
  text-decoration: none;
}

:global .btn-link[disabled]:hover {
  color: #888;
  text-decoration: none;
}

:global .btn-link[disabled]:focus {
  color: #888;
  text-decoration: none;
}

fieldset[disabled] :global .btn-link:hover {
  color: #888;
  text-decoration: none;
}

fieldset[disabled] :global .btn-link:focus {
  color: #888;
  text-decoration: none;
}

:global .dibs-btn-link.disabled:hover {
  color: #888;
  text-decoration: none;
}

:global .dibs-btn-link.disabled:focus {
  color: #888;
  text-decoration: none;
}

:global .dibs-btn-link[disabled]:hover {
  color: #888;
  text-decoration: none;
}

:global .dibs-btn-link[disabled]:focus {
  color: #888;
  text-decoration: none;
}

fieldset[disabled] :global .dibs-btn-link:hover {
  color: #888;
  text-decoration: none;
}

fieldset[disabled] :global .dibs-btn-link:focus {
  color: #888;
  text-decoration: none;
}

:global .btn-lg {
  border-radius: 2px;
  padding: 12px 20px;
  font-size: 18px;
  line-height: 1.33;
}

:global .btn-group-lg > .btn {
  border-radius: 2px;
  padding: 12px 20px;
  font-size: 18px;
  line-height: 1.33;
}

:global .btn-md {
  border-radius: 2px;
  padding: 8px 17px;
  font-size: 16px;
  line-height: 1.33;
}

:global .btn-sm {
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

:global .btn-group-sm > .btn {
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

:global .btn-xs {
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

:global .btn-group-xs > .btn {
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

:global .btn-xs {
  padding: 2px 5px 0;
  font-size: 11px;
}

:global .btn-group-xs > .btn {
  padding: 2px 5px 0;
  font-size: 11px;
}

:global .btn-block {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  display: block;
}

:global .btn-block + .btn-block {
  margin-top: 5px;
}

:is(:global input[type="submit"].btn-block, :global input[type="reset"].btn-block, :global input[type="button"].btn-block) {
  width: 100%;
}

:global .btn-wrap {
  white-space: normal;
}

:global .fade {
  opacity: 0;
  transition: opacity .15s linear;
}

:global .fade.in {
  opacity: 1;
}

:global .collapse {
  display: none;
}

:global .collapse.in {
  display: block;
}

:global tbody.collapse.in {
  display: table-row-group;
}

:global .collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

:global .recessed {
  transform-origin: 100% 0;
  animation: .7s ease-out forwards recess;
}

@-webkit-keyframes recess {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: .6;
    transform: scale(.8);
  }

  0% {
    opacity: 1;
    -webkit-transform: scale(1);
  }

  100% {
    opacity: .6;
    -webkit-transform: scale(.8);
  }
}

:global .full {
  transform-origin: 100% 0;
  animation: .7s ease-out full;
}

@-webkit-keyframes full {
  0% {
    opacity: .6;
    transform: scale(.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }

  0% {
    opacity: .6;
    -webkit-transform: scale(.8);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

:global .caret {
  vertical-align: middle;
  border: 4px solid #0000;
  border-top-color: #222;
  border-bottom: 0 dotted;
  width: 0;
  height: 0;
  margin-left: 2px;
  display: inline-block;
}

:global .dropdown {
  position: relative;
}

:global .dropdown-toggle:focus {
  outline: 0;
}

:global .dropdown-menu {
  z-index: 1060;
  float: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #22222226;
  border-radius: 2px;
  min-width: 160px;
  margin: 0;
  padding: 5px 0;
  font-size: 14px;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 6px 12px #2222222d;
}

:global .dropdown-menu.pull-right {
  left: auto;
  right: 0;
}

:global .dropdown-menu .divider {
  background-color: #ddd;
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
}

:global .dropdown-menu > li > a {
  clear: both;
  color: #222;
  white-space: nowrap;
  padding: 3px 20px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  line-height: 1.5;
  display: block;
}

:is(:global .dropdown-menu > li > a:hover, :global .dropdown-menu > li > a:focus) {
  color: #436b93;
  background-color: #0000;
  text-decoration: none;
}

:global .dropdown-menu > .active > a {
  color: #436b93;
  background-color: #0000;
  outline: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
  text-decoration: none;
}

:global .dropdown-menu > .active > a:focus {
  color: #436b93;
  background-color: #0000;
  outline: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
  text-decoration: none;
}

:global .dropdown-menu > .active > a:hover {
  color: #fff;
  background-color: #436b93;
}

:global .dropdown-menu > .disabled > a {
  color: #888;
}

:global .dropdown-menu > .disabled > a:hover {
  color: #888;
}

:global .dropdown-menu > .disabled > a:focus {
  color: #888;
}

:is(:global .dropdown-menu > .disabled > a:hover, :global .dropdown-menu > .disabled > a:focus) {
  cursor: not-allowed;
  background-color: #0000;
  background-image: none;
  text-decoration: none;
}

:global .open > .dropdown-menu {
  display: block;
}

:global .open > a {
  outline: 0;
}

:global .dropdown-header {
  color: #888;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.5;
  display: block;
}

:global .dropdown-backdrop {
  z-index: 1050;
  position: fixed;
  inset: 0;
}

:global .pull-right > .dropdown-menu {
  left: auto;
  right: 0;
}

:global .dropup .caret {
  content: "";
  border-top: 0 dotted;
  border-bottom: 4px solid #222;
}

:global .navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0 dotted;
  border-bottom: 4px solid #222;
}

:global .dropup .dropdown-menu {
  margin-bottom: 1px;
  top: auto;
  bottom: 100%;
}

:global .navbar-fixed-bottom .dropdown .dropdown-menu {
  margin-bottom: 1px;
  top: auto;
  bottom: 100%;
}

@media (width >= 769px) {
  :global .navbar-right .dropdown-menu {
    left: auto;
    right: 0;
  }

  :global .navbar .dropdown-menu {
    top: 85%;
  }

  :global .navbar .dropdown-menu:after {
    content: "";
    border-bottom: 4px solid #fff;
    border-left: 4px solid #0000;
    border-right: 4px solid #0000;
    display: inline-block;
    position: absolute;
    top: -4px;
    left: 14px;
  }

  :global .navbar .navbar-right .dropdown-menu:after {
    left: auto;
    right: 14px;
  }
}

.btn-default :global .caret {
  border-top-color: #222;
}

:is(.btn-primary :global .caret, .btn-success :global .caret, .btn-warning :global .caret, .btn-danger :global .caret, .btn-info :global .caret) {
  border-top-color: #fff;
}

:global .dropup .btn-default .caret {
  border-bottom-color: #222;
}

:is(:global .dropup .btn-primary .caret, :global .dropup .btn-success .caret, :global .dropup .btn-warning .caret, :global .dropup .btn-danger .caret, :global .dropup .btn-info .caret) {
  border-bottom-color: #fff;
}

:is(:global .btn-group, :global .btn-group-vertical) {
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

:is(:global .btn-group > .btn, :global .btn-group-vertical > .btn) {
  float: left;
  position: relative;
}

:is(:global .btn-group > .btn:hover, :global .btn-group > .btn:focus, :global .btn-group > .btn:active, :global .btn-group > .btn.active, :global .btn-group-vertical > .btn:hover, :global .btn-group-vertical > .btn:focus, :global .btn-group-vertical > .btn:active, :global .btn-group-vertical > .btn.active) {
  z-index: 2;
}

:is(:global .btn-group > .btn:focus, :global .btn-group-vertical > .btn:focus) {
  outline: none;
}

:is(:global .btn-group .btn + .btn, :global .btn-group .btn + .btn-group, :global .btn-group .btn-group + .btn, :global .btn-group .btn-group + .btn-group) {
  margin-left: -1px;
}

:global .btn-toolbar:before {
  content: " ";
  display: table;
}

:global .btn-toolbar:after {
  content: " ";
  display: table;
}

:global .btn-toolbar:after {
  clear: both;
}

:global .btn-toolbar .btn-group {
  float: left;
}

:is(:global .btn-toolbar > .btn + .btn, :global .btn-toolbar > .btn + .btn-group, :global .btn-toolbar > .btn-group + .btn, :global .btn-toolbar > .btn-group + .btn-group) {
  margin-left: 5px;
}

:global .btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

:global .btn-group > .btn:first-child {
  margin-left: 0;
}

:global .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global .btn-group > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:global .btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:global .btn-group > .btn-group {
  float: left;
}

:global .btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

:global .btn-group > .btn-group:first-child > .btn:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global .btn-group > .btn-group:first-child > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global .btn-group > .btn-group:last-child > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:is(:global .btn-group .dropdown-toggle:active, :global .btn-group.open .dropdown-toggle) {
  outline: 0;
}

:global .btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}

:global .btn-group > .btn-lg + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}

:global .btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}

:global .btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px #22222220;
}

:global .btn-group.open .dropdown-toggle.btn-link {
  box-shadow: none;
}

:global .btn .caret {
  margin-left: 0;
}

:global .btn-lg .caret {
  border-width: 5px 5px 0;
}

:global .btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
}

:global .dropup .btn-lg .caret {
  border-width: 0 5px 5px;
}

:global .dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px;
}

:is(:global .btn-group-vertical > .btn, :global .btn-group-vertical > .btn-group) {
  float: none;
  width: 100%;
  max-width: 100%;
  display: block;
}

:global .btn-group-vertical > .btn-group:before {
  content: " ";
  display: table;
}

:global .btn-group-vertical > .btn-group:after {
  content: " ";
  display: table;
}

:global .btn-group-vertical > .btn-group:after {
  clear: both;
}

:global .btn-group-vertical > .btn-group > .btn {
  float: none;
}

:is(:global .btn-group-vertical > .btn + .btn, :global .btn-group-vertical > .btn + .btn-group, :global .btn-group-vertical > .btn-group + .btn, :global .btn-group-vertical > .btn-group + .btn-group) {
  margin-top: -1px;
  margin-left: 0;
}

:global .btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

:global .btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

:global .btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 2px;
}

:global .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

:global .btn-group-vertical > .btn-group:first-child > .btn:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

:global .btn-group-vertical > .btn-group:first-child > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

:global .btn-group-vertical > .btn-group:last-child > .btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

:global .btn-group-justified {
  table-layout: fixed;
  border-collapse: separate;
  width: 100%;
  display: table;
}

:global .btn-group-justified .btn {
  float: none;
  width: 1%;
  display: table-cell;
}

:is(:global [data-toggle="buttons"] > .btn > input[type="radio"], :global [data-toggle="buttons"] > .btn > input[type="checkbox"]) {
  display: none;
}

:global .input-group {
  border-collapse: separate;
  display: table;
  position: relative;
}

:global .input-group.col {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

:global .input-group .form-control {
  width: 100%;
  margin-bottom: 0;
}

:global .input-group-addon {
  display: table-cell;
}

:global .input-group-btn {
  display: table-cell;
}

:global .input-group .form-control {
  display: table-cell;
}

:global .input-group-addon:not(:first-child):not(:last-child) {
  border-radius: 0;
}

:global .input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

:global .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

:is(:global .input-group-addon, :global .input-group-btn) {
  white-space: nowrap;
  vertical-align: middle;
  width: 1%;
}

:global .input-group-addon {
  color: #222;
  text-align: center;
  background-color: #ddd;
  border: 1px solid #bbb;
  border-radius: 2px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1;
}

:global .input-group-addon.input-sm {
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
}

:global .input-group-sm > .input-group-addon {
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
}

:global .input-group-sm > .input-group-btn > .input-group-addon.btn {
  border-radius: 2px;
  padding: 5px 10px;
  font-size: 12px;
}

:global .input-group-addon.input-lg {
  border-radius: 2px;
  padding: 12px 20px;
  font-size: 18px;
}

:global .input-group-lg > .input-group-addon {
  border-radius: 2px;
  padding: 12px 20px;
  font-size: 18px;
}

:global .input-group-lg > .input-group-btn > .input-group-addon.btn {
  border-radius: 2px;
  padding: 12px 20px;
  font-size: 18px;
}

:is(:global .input-group-addon input[type="radio"], :global .input-group-addon input[type="checkbox"]) {
  margin-top: 0;
}

:global .input-group .form-control:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global .input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global .input-group-btn:first-child > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global .input-group-btn:first-child > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:global .input-group-addon:first-child {
  border-right: 0;
}

:global .input-group .form-control:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:global .input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:global .input-group-btn:last-child > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:global .input-group-btn:last-child > .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:global .input-group-btn:first-child > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:global .input-group-addon:last-child {
  border-left: 0;
}

:global .input-group-btn {
  white-space: nowrap;
  position: relative;
}

:global .input-group-btn:first-child > .btn {
  margin-right: -1px;
}

:global .input-group-btn:last-child > .btn {
  margin-left: -1px;
}

:global .input-group-btn > .btn {
  position: relative;
}

:global .input-group-btn > .btn + .btn {
  margin-left: -4px;
}

:is(:global .input-group-btn > .btn:hover, :global .input-group-btn > .btn:active) {
  z-index: 2;
}

:global .nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

:global .nav:before {
  content: " ";
  display: table;
}

:global .nav:after {
  content: " ";
  display: table;
}

:global .nav:after {
  clear: both;
}

:global .nav > li {
  display: block;
  position: relative;
}

:global .nav > li > a {
  padding: 9px;
  display: block;
  position: relative;
}

:global .nav > li.disabled > a {
  color: #888;
}

:is(:global .nav > li.disabled > a:hover, :global .nav > li.disabled > a:focus) {
  color: #888;
  cursor: not-allowed;
}

:global .nav .open > a {
  background-color: #ddd;
  border-color: #436b93;
}

:global .nav .open > a:hover {
  background-color: #ddd;
  border-color: #436b93;
}

:global .nav .open > a:focus {
  background-color: #ddd;
  border-color: #436b93;
}

:global .nav .open > a .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}

:global .nav .open > a:hover .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}

:global .nav .open > a:focus .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}

:global .nav .nav-divider {
  background-color: #ddd;
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
}

:global .nav > li > a > img {
  max-width: none;
}

:global .nav.nav-tabs {
  border-bottom: 1px solid #bbb;
}

:global .nav.nav-tabs > li {
  color: #436b93;
  float: left;
  background-color: #bbb;
  border-radius: 1px;
  margin-bottom: -1px;
  margin-right: 6px;
  transition: background-color .15s;
}

:global .nav.nav-tabs > li.active {
  background-color: #204664;
}

:global .nav.nav-tabs > li.dropdown.open a.dropdown-toggle {
  background-color: #bbb;
  border-color: #0000;
}

:global .nav.nav-tabs > li > a {
  color: #436b93;
  background-color: #bbb;
  border: none;
  border-left: 1px solid #0000;
  border-right: 1px solid #0000;
  border-radius: 0;
  margin-top: 2px;
  margin-bottom: 0;
  padding: 10px 28px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
  transition: background-color .15s;
}

:global .nav.nav-tabs > li > a:hover {
  background-color: #bbb;
}

:is(:global .nav.nav-tabs > li > a .badge, :global .nav.nav-tabs > li > a .badge-primary) {
  color: inherit;
  background-color: #0000;
  margin-left: 11px;
  margin-right: -7px;
}

:is(:global .nav.nav-tabs > li.active .badge, :global .nav.nav-tabs > li.active .badge-primary) {
  color: #fff;
  background-color: #204664;
  margin-right: 0;
}

:global .nav.nav-tabs > li.active > a {
  background-color: #fff;
  border-top: none;
  border-bottom: none;
}

:global .nav.nav-tabs > li.active > a {
  cursor: default;
  color: #204664;
  background-color: #fff;
  border: none;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
}

:global .nav.nav-tabs > li.active > a:hover {
  cursor: default;
  color: #204664;
  background-color: #fff;
  border: none;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
}

:global .nav.nav-tabs > li.active > a:focus {
  cursor: default;
  color: #204664;
  background-color: #fff;
  border: none;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
}

:is(:global .nav.nav-tabs.has-active-sub-tab > li.active .badge, :global .nav.nav-tabs.has-active-sub-tab > li.active .badge-primary) {
  color: inherit;
  background-color: inherit;
  margin-right: -7px;
}

:global .nav.nav-tabs-md > li > a {
  padding: 7px 15px;
}

:global .nav.nav-sub-tabs {
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  border-radius: 0;
  padding-top: 4px;
  font-size: 12px;
  font-weight: 300;
  position: relative;
  top: -1px;
}

:global .nav.nav-sub-tabs > li {
  float: left;
}

:global .nav.nav-sub-tabs > li:first-child a {
  border-left: none;
}

:global .nav.nav-sub-tabs > li:hover > a {
  color: #204664;
}

:global .nav.nav-sub-tabs > li > a {
  color: #436b93;
  background-color: inherit;
  border: none;
  border-left: 1px solid #bbb;
  border-radius: 0;
  margin-bottom: 1px;
  margin-right: 0;
  padding: 5px 20px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  line-height: 1.5em;
  transition: color .2s;
  position: relative;
}

:is(:global .nav.nav-sub-tabs > li > a .badge, :global .nav.nav-sub-tabs > li > a .badge-primary) {
  color: inherit;
  background-color: #0000;
  margin-left: 11px;
  margin-right: -7px;
}

:global .nav.nav-sub-tabs > li > a:after {
  content: "";
  opacity: 0;
  border-top: 8px solid #204664;
  border-left: 16px solid #0000;
  border-right: 16px solid #0000;
  margin-left: -16px;
  transition: opacity 20ms;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
}

:global .nav.nav-sub-tabs > li.active {
  border-top-color: #204664;
}

:is(:global .nav.nav-sub-tabs > li.active .badge, :global .nav.nav-sub-tabs > li.active .badge-primary) {
  color: #fff;
  background-color: #204664;
  margin-right: 0;
}

:global .nav.nav-sub-tabs > li.active > a {
  color: #204664;
  background-color: #fff;
  border-top: none;
  border-bottom: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

:global .nav.nav-sub-tabs > li.active > a {
  color: #204664;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

:global .nav.nav-sub-tabs > li.active > a:hover {
  color: #204664;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

:global .nav.nav-sub-tabs > li.active > a:focus {
  color: #204664;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

:global .nav.nav-sub-tabs > li.active > a:after {
  opacity: 1;
  bottom: -10px;
}

@media (width >= 769px) and (width <= 1024px) {
  :is(:global .nav.nav-tabs, :global .nav.nav-sub-tabs) {
    display: flex;
  }

  :global .nav.nav-tabs:before {
    display: none;
  }

  :global .nav.nav-tabs:after {
    display: none;
  }

  :global .nav.nav-sub-tabs:before {
    display: none;
  }

  :global .nav.nav-sub-tabs:after {
    display: none;
  }

  :is(:global .nav.nav-tabs > li, :global .nav.nav-sub-tabs > li) {
    flex: 1, 1, auto;
  }

  :is(:global .nav.nav-tabs > li:last-child, :global .nav.nav-sub-tabs > li:last-child) {
    margin-right: 0;
  }

  :is(:global .nav.nav-tabs > li > a, :global .nav.nav-sub-tabs > li > a) {
    padding-left: 10px;
    padding-right: 10px;
    line-height: 18px;
  }

  :is(:global .nav.nav-tabs > li > a .badge, :global .nav.nav-tabs > li > a .badge-primary, :global .nav.nav-sub-tabs > li > a .badge, :global .nav.nav-sub-tabs > li > a .badge-primary) {
    float: right;
    margin-left: 0;
  }

  :is(:global .nav.nav-tabs .bucket-filter, :global .nav.nav-sub-tabs .bucket-filter) {
    width: 100%;
  }
}

@media (width <= 768px) {
  :is(:global .nav.nav-tabs, :global .nav.nav-sub-tabs) {
    border-bottom: none;
    flex-direction: column;
    display: flex;
  }

  :is(:global .nav.nav-tabs > li, :global .nav.nav-sub-tabs > li) {
    flex: 0, 0, auto;
    margin-bottom: 1px;
    margin-right: 0;
  }

  :is(:global .nav.nav-tabs > li > a, :global .nav.nav-sub-tabs > li > a) {
    border: none;
  }

  :is(:global .nav.nav-tabs > li > a:focus, :global .nav.nav-sub-tabs > li > a:focus) {
    outline: none;
  }

  :global .nav.nav-tabs > li > a:after {
    display: none;
  }

  :global .nav.nav-sub-tabs > li > a:after {
    display: none;
  }

  :global .nav.nav-tabs > li.active > a {
    border: none;
  }

  :global .nav.nav-tabs > li.active a:hover {
    border: none;
  }

  :global .nav.nav-sub-tabs > li.active > a {
    border: none;
  }

  :global .nav.nav-sub-tabs > li.active a:hover {
    border: none;
  }
}

@media (width <= 568px) {
  :is(:global .nav.nav-tabs > li > a, :global .nav.nav-sub-tabs > li > a) {
    height: 38px;
    line-height: 18px;
  }
}

@media (width <= 768px) {
  :global .two-level-bucket-view > .nav.nav-sub-tabs {
    display: none;
  }

  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs {
    display: none;
  }

  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs {
    background-color: #fff;
    border-top: none;
    padding-top: 0;
  }

  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs li {
    border-top: 1px solid #bbb;
    margin-bottom: 0;
  }

  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs li > a {
    margin-bottom: 0;
  }

  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs li > a .bucket-filter-count {
    color: #204664;
    background-color: #fff;
  }

  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs li.active > a .bucket-filter-count {
    color: #fff;
    background-color: #204664;
  }
}

@media (width <= 568px) {
  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs li > a {
    height: 28px;
  }
}

@media (width <= 768px) {
  :global .omt-tabs-your-action-required-container > .nav.nav-sub-tabs .zero-badge-count-bucket {
    display: none;
  }
}

:global .nav-pills > li {
  float: left;
}

:global .nav-pills > li > a {
  border-radius: 2px;
}

:global .nav-pills > li + li {
  margin-left: 2px;
}

:global .nav-pills > li.active > a {
  color: #fff;
  background-color: #436b93;
}

:global .nav-pills > li.active > a:hover {
  color: #fff;
  background-color: #436b93;
}

:global .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #436b93;
}

:global .nav-pills > li.active > a .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

:global .nav-pills > li.active > a:hover .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

:global .nav-pills > li.active > a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

:global .nav-stacked > li {
  float: none;
}

:global .nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0;
}

:global .nav-justified {
  width: 100%;
}

:global .nav.nav-tabs.nav-justified {
  width: 100%;
}

:global .nav-justified > li {
  float: none;
}

:global .nav.nav-tabs.nav-justified > li {
  float: none;
}

:global .nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}

:global .nav.nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px;
}

:global .nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (width >= 769px) {
  :global .nav-justified > li {
    width: 1%;
    display: table-cell;
  }

  :global .nav.nav-tabs.nav-justified > li {
    width: 1%;
    display: table-cell;
  }

  :global .nav-justified > li > a {
    margin-bottom: 0;
  }

  :global .nav.nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
}

:global .nav-tabs-justified {
  border-bottom: 0;
}

:global .nav.nav-tabs.nav-justified {
  border-bottom: 0;
}

:global .nav-tabs-justified > li > a {
  border-radius: 2px;
  margin-right: 0;
}

:global .nav.nav-tabs.nav-justified > li > a {
  border-radius: 2px;
  margin-right: 0;
}

:global .nav-tabs-justified > .active > a {
  border: 1px solid #ddd;
}

:global .nav.nav-tabs.nav-justified > .active > a {
  border: 1px solid #ddd;
}

:global .nav-tabs-justified > .active > a:hover {
  border: 1px solid #ddd;
}

:global .nav-tabs-justified > .active > a:focus {
  border: 1px solid #ddd;
}

@media (width >= 769px) {
  :global .nav-tabs-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 2px 2px 0 0;
  }

  :global .nav.nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 2px 2px 0 0;
  }

  :global .nav-tabs-justified > .active > a {
    border-bottom-color: #fff;
  }

  :global .nav.nav-tabs.nav-justified > .active > a {
    border-bottom-color: #fff;
  }

  :global .nav-tabs-justified > .active > a:hover {
    border-bottom-color: #fff;
  }

  :global .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
}

:global .tab-content > .tab-pane {
  display: none;
}

:global .tab-content > .active {
  display: block;
}

:global .nav .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}

:global .nav a:hover .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}

:global .nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

:global .nav-list {
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
}

:global .nav-list > li > a {
  margin-left: -10px;
  margin-right: -10px;
  padding: 5px 10px;
}

:global .nav-header {
  text-shadow: 0 1px #ffffff80;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #888;
  margin-left: -10px;
  margin-right: -10px;
  padding: 5px 10px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
}

:global .navbar {
  border: 1px solid #0000;
  min-height: 61px;
  margin-bottom: 20px;
  position: relative;
}

:global .navbar:before {
  content: " ";
  display: table;
}

:global .navbar:after {
  content: " ";
  display: table;
}

:global .navbar:after {
  clear: both;
}

@media (width >= 769px) {
  :global .navbar {
    border-radius: 2px;
  }
}

:global .navbar-header:before {
  content: " ";
  display: table;
}

:global .navbar-header:after {
  content: " ";
  display: table;
}

:global .navbar-header:after {
  clear: both;
}

@media (width >= 769px) {
  :global .navbar-header {
    float: left;
  }
}

:global .navbar-collapse {
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid #0000;
  max-height: 450px;
  padding-left: 15px;
  padding-right: 15px;
  overflow-x: visible;
  box-shadow: inset 0 1px #ffffff1a;
}

:global .navbar-collapse:before {
  content: " ";
  display: table;
}

:global .navbar-collapse:after {
  content: " ";
  display: table;
}

:global .navbar-collapse:after {
  clear: both;
}

:global .navbar-collapse.in {
  overflow-y: auto;
}

@media (width >= 769px) {
  :global .navbar-collapse {
    width: auto;
    box-shadow: none;
    border-top: 0;
  }

  :global .navbar-collapse.collapse {
    align-items: center;
    padding-bottom: 0;
    height: auto !important;
    display: flex !important;
    overflow: visible !important;
  }

  :global .navbar-collapse.in {
    overflow-y: auto;
  }

  :global .navbar-collapse .navbar-nav.navbar-left:first-child {
    margin-left: -15px;
  }

  :global .navbar-collapse .navbar-nav.navbar-right:last-child {
    margin-right: -15px;
  }

  :global .navbar-collapse .navbar-text:last-child {
    margin-right: 0;
  }
}

:is(:global .container > .navbar-header, :global .container > .navbar-collapse) {
  margin-left: -15px;
  margin-right: -15px;
}

@media (width >= 769px) {
  :is(:global .container > .navbar-header, :global .container > .navbar-collapse) {
    margin-left: 0;
    margin-right: 0;
  }
}

:global .navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

@media (width >= 769px) {
  :global .navbar-static-top {
    border-radius: 0;
  }
}

:is(:global .navbar-fixed-top, :global .navbar-fixed-bottom) {
  z-index: 1020;
  position: fixed;
  left: 0;
  right: 0;
}

@media (width >= 769px) {
  :is(:global .navbar-fixed-top, :global .navbar-fixed-bottom) {
    border-radius: 0;
  }
}

:global .navbar-fixed-top {
  border-width: 3px 0 1px;
  top: 0;
}

@media (width <= 768px) {
  :global .navbar-fixed-top #dibsy-navbar-collapse {
    width: 100%;
  }
}

:global .navbar-fixed-bottom {
  border-width: 1px 0 0;
  margin-bottom: 0;
  bottom: 0;
}

:global .navbar-brand {
  float: left;
  padding: 20.5px 0;
  font-size: 18px;
  line-height: 20px;
}

:is(:global .navbar-brand:hover, :global .navbar-brand:focus) {
  text-decoration: none;
}

@media (width >= 769px) {
  .navbar > .container :global .navbar-brand {
    margin-left: -15px;
  }
}

:global .navbar-toggle {
  cursor: pointer;
  float: right;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 2px;
  outline: none;
  margin-top: 13.5px;
  margin-bottom: 13.5px;
  margin-right: 15px;
  padding: 9px 10px;
  position: relative;
}

:global .navbar-toggle:hover .icon-bar {
  background-color: #559b5e !important;
}

:global .navbar-toggle .icon-bar {
  border-radius: 1px;
  width: 22px;
  height: 2px;
  display: block;
}

:global .navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}

@media (width >= 769px) {
  :global .navbar-toggle {
    display: none;
  }
}

:global .navbar-nav {
  margin: 10.25px -15px;
}

:global .navbar-nav > li > a {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  line-height: 20px;
}

:global .navbar-nav .navbar-badge {
  text-transform: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
}

@media (width <= 768px) {
  :global .navbar-nav .open .dropdown-menu {
    float: none;
    width: auto;
    box-shadow: none;
    background-color: #0000;
    border: 0;
    margin-top: 0;
    position: static;
  }

  :global .navbar-nav .open .dropdown-menu > li > a {
    padding: 5px 15px 5px 25px;
  }

  :global .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }

  :global .navbar-nav .open .dropdown-menu > li > a {
    line-height: 20px;
  }

  :is(:global .navbar-nav .open .dropdown-menu > li > a:hover, :global .navbar-nav .open .dropdown-menu > li > a:focus) {
    background-image: none;
  }
}

@media (width >= 769px) {
  :global .navbar-nav {
    float: left;
    margin: 0;
  }

  :global .navbar-nav > li {
    float: left;
  }

  :global .navbar-nav > li > a {
    padding-top: 20.5px;
    padding-bottom: 20.5px;
  }
}

:global .navbar-nav.navbar-no-collapse {
  float: left;
  margin: 0;
}

:global .navbar-nav.navbar-no-collapse > li {
  float: left;
}

:global .navbar-nav.navbar-no-collapse > li > a {
  padding-top: 20.5px;
  padding-bottom: 20.5px;
}

@media (width >= 769px) {
  :global .navbar-left {
    float: left !important;
  }

  :global .navbar-right {
    float: right !important;
  }
}

:global .navbar-always-left {
  float: left !important;
}

:global .navbar-always-right {
  float: right !important;
}

:global .navbar-form {
  border-top: 1px solid #0000;
  border-bottom: 1px solid #0000;
  margin: 14px -15px;
  padding: 10px 15px;
  box-shadow: inset 0 1px #ffffff1a, 0 1px #ffffff1a;
}

@media (width <= 568px) {
  :global .navbar-form .form-group {
    margin-bottom: 5px;
  }
}

@media (width >= 769px) {
  :global .navbar-form {
    width: auto;
    box-shadow: none;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

:global .navbar-nav > li > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
}

:global .navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

:is(:global .navbar-nav.pull-right > li > .dropdown-menu, :global .navbar-nav > li > .dropdown-menu.pull-right) {
  left: auto;
  right: 0;
}

:global .navbar-btn {
  margin-top: 14px;
  margin-bottom: 14px;
}

:global .navbar-text {
  float: left;
  margin-top: 20.5px;
  margin-bottom: 20.5px;
}

@media (width >= 769px) {
  :global .navbar-text {
    margin-left: 15px;
    margin-right: 15px;
  }
}

:global .navbar-default {
  background-color: #fbfbfb;
  border-color: #f3f3f3;
}

:global .navbar-default .navbar-brand {
  color: #888;
}

:is(:global .navbar-default .navbar-brand:hover, :global .navbar-default .navbar-brand:focus) {
  color: #666;
  background-color: #0000;
}

:global .navbar-default .navbar-text {
  color: #888;
}

:global .navbar-default .navbar-nav > li > a {
  color: #888;
}

:is(:global .navbar-default .navbar-nav > li > a:hover, :global .navbar-default .navbar-nav > li > a:focus) {
  color: #222;
  background-color: #0000;
}

:global .navbar-default .navbar-nav > .active > a {
  color: #666;
  background-color: #f3f3f3;
}

:global .navbar-default .navbar-nav > .active > a:hover {
  color: #666;
  background-color: #f3f3f3;
}

:global .navbar-default .navbar-nav > .active > a:focus {
  color: #666;
  background-color: #f3f3f3;
}

:global .navbar-default .navbar-nav > .disabled > a {
  color: #bbb;
  background-color: #0000;
}

:global .navbar-default .navbar-nav > .disabled > a:hover {
  color: #bbb;
  background-color: #0000;
}

:global .navbar-default .navbar-nav > .disabled > a:focus {
  color: #bbb;
  background-color: #0000;
}

:global .navbar-default .navbar-toggle {
  border-color: #ddd;
}

:is(:global .navbar-default .navbar-toggle:hover, :global .navbar-default .navbar-toggle:focus) {
  background-color: #ddd;
}

:global .navbar-default .navbar-toggle .icon-bar {
  background-color: #bbb;
}

:is(:global .navbar-default .navbar-collapse, :global .navbar-default .navbar-form) {
  border-color: #f3f3f3;
}

:is(:global .navbar-default .navbar-nav > .dropdown > a:hover .caret, :global .navbar-default .navbar-nav > .dropdown > a:focus .caret) {
  border-top-color: #222;
  border-bottom-color: #222;
}

:global .navbar-default .navbar-nav > .open > a {
  color: #666;
  background-color: #f3f3f3;
}

:global .navbar-default .navbar-nav > .open > a:hover {
  color: #666;
  background-color: #f3f3f3;
}

:global .navbar-default .navbar-nav > .open > a:focus {
  color: #666;
  background-color: #f3f3f3;
}

:global .navbar-default .navbar-nav > .open > a .caret {
  border-top-color: #666;
  border-bottom-color: #666;
}

:global .navbar-default .navbar-nav > .open > a:hover .caret {
  border-top-color: #666;
  border-bottom-color: #666;
}

:global .navbar-default .navbar-nav > .open > a:focus .caret {
  border-top-color: #666;
  border-bottom-color: #666;
}

:global .navbar-default .navbar-nav > .dropdown > a .caret {
  border-top-color: #888;
  border-bottom-color: #888;
}

@media (width <= 768px) {
  :global .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #888;
  }

  :is(:global .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, :global .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus) {
    color: #222;
    background-color: #0000;
  }

  :global .navbar-default .navbar-nav .open .dropdown-menu > .active > a {
    color: #666;
    background-color: #f3f3f3;
  }

  :global .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #666;
    background-color: #f3f3f3;
  }

  :global .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #666;
    background-color: #f3f3f3;
  }

  :global .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a {
    color: #bbb;
    background-color: #0000;
  }

  :global .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #bbb;
    background-color: #0000;
  }

  :global .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #bbb;
    background-color: #0000;
  }
}

:global .navbar-default .navbar-link {
  color: #888;
}

:global .navbar-default .navbar-link:hover {
  color: #222;
}

:global .navbar-inverse {
  background-color: #222;
  border-color: #436b93;
}

:global .navbar-inverse .navbar-brand {
  color: #fff;
}

:is(:global .navbar-inverse .navbar-brand:hover, :global .navbar-inverse .navbar-brand:focus) {
  color: #fff;
  background-color: #0000;
}

:global .navbar-inverse .navbar-text {
  color: #ddd;
}

:global .navbar-inverse .navbar-nav > li > a {
  color: #fff;
}

:is(:global .navbar-inverse .navbar-nav > li > a:hover, :global .navbar-inverse .navbar-nav > li > a:focus) {
  color: #436b93;
  background-color: #0000;
}

:global .navbar-inverse .navbar-nav > .active > a {
  color: #436b93;
  background-color: #0000;
}

:global .navbar-inverse .navbar-nav > .active > a:hover {
  color: #436b93;
  background-color: #0000;
}

:global .navbar-inverse .navbar-nav > .active > a:focus {
  color: #436b93;
  background-color: #0000;
}

:global .navbar-inverse .navbar-nav > .disabled > a {
  color: #666;
  background-color: #0000;
}

:global .navbar-inverse .navbar-nav > .disabled > a:hover {
  color: #666;
  background-color: #0000;
}

:global .navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #666;
  background-color: #0000;
}

:global .navbar-inverse .navbar-toggle {
  border-color: #222;
}

:is(:global .navbar-inverse .navbar-toggle:hover, :global .navbar-inverse .navbar-toggle:focus) {
  background-color: #222;
}

:global .navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

:is(:global .navbar-inverse .navbar-collapse, :global .navbar-inverse .navbar-form) {
  border-color: #222;
}

:global .navbar-inverse .navbar-nav > .open > a {
  color: #436b93;
  background-color: #0000;
}

:global .navbar-inverse .navbar-nav > .open > a:hover {
  color: #436b93;
  background-color: #0000;
}

:global .navbar-inverse .navbar-nav > .open > a:focus {
  color: #436b93;
  background-color: #0000;
}

:global .navbar-inverse .navbar-nav > .dropdown > a:hover .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}

:global .navbar-inverse .navbar-nav > .dropdown > a .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

:global .navbar-inverse .navbar-nav > .open > a .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}

:global .navbar-inverse .navbar-nav > .open > a:hover .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}

:global .navbar-inverse .navbar-nav > .open > a:focus .caret {
  border-top-color: #436b93;
  border-bottom-color: #436b93;
}

:is(:global .navbar-inverse .navbar-nav.user-label, :global .navbar-inverse .navbar-nav.masquerade-label) {
  float: right;
  margin-left: -15px;
  padding-left: 0;
  display: inline-block;
}

:global .navbar-inverse .navbar-nav .user-link {
  float: right;
  max-width: inherit;
  text-align: right;
  padding-left: 0;
}

:global .navbar-inverse .navbar-nav .user-link #company-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
  margin-bottom: -6px;
  display: inline-block;
  overflow: hidden;
}

:is(:global .navbar-inverse .navbar-nav .dropdown-menu > li.dropdown-user-info > a:hover, :global .navbar-inverse .navbar-nav .dropdown-menu > li.dropdown-user-info > a:focus) {
  color: #000;
  cursor: default;
}

@media (width >= 769px) and (width <= 1024px) {
  :is(:global .navbar-inverse .navbar-nav.masquerade-label, :global .navbar-inverse .navbar-nav.user-label) {
    max-width: 230px;
  }

  :is(:global .navbar-inverse .navbar-nav #user-name, :global .navbar-inverse .navbar-nav #user-name-short) {
    display: none !important;
  }

  :global .navbar-inverse .navbar-nav #company-name {
    max-width: 150px;
  }
}

@media (width >= 569px) and (width <= 1024px) {
  :global .navbar-inverse .navbar-nav #user-name {
    display: none !important;
  }

  :global .navbar-inverse .navbar-nav #user-name-short {
    display: none !important;
  }

  :global .navbar-inverse .navbar-nav #company-name {
    display: none !important;
  }

  :global .navbar-inverse .navbar-nav .masquerade-wrapper {
    display: none !important;
  }
}

@media (width >= 769px) {
  :global .navbar-inverse .navbar-nav .dropdown-user-info {
    display: none !important;
  }
}

@media (width <= 1024px) {
  :global .navbar-inverse .navbar-nav.masquerade-label > .dropdown {
    max-width: 200px;
  }
}

@media (width <= 568px) {
  :global .navbar-inverse .navbar-nav #company-name {
    display: none !important;
  }

  :is(:global .navbar-inverse .navbar-nav.user-label > .dropdown, :global .navbar-inverse .navbar-nav.masquerade-label > .dropdown) {
    max-width: 140px;
  }

  :global .navbar-inverse .navbar-nav .dropdown-menu > .dropdown-user-info > a.use-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
    display: inline-block;
    overflow: hidden;
  }
}

@media (width >= 569px) and (width <= 768px) {
  :is(:global .navbar-inverse .navbar-nav.masquerade-label, :global .navbar-inverse .navbar-nav.user-label) {
    max-width: 260px;
  }

  :global .navbar-inverse .navbar-nav #company-name {
    max-width: 180px;
  }
}

@media (width <= 568px) {
  :is(:global .navbar-inverse .navbar-nav.masquerade-label, :global .navbar-inverse .navbar-nav.user-label) {
    max-width: 200px;
  }

  :is(:global .navbar-inverse .navbar-nav #user-name, :global .navbar-inverse .navbar-nav #user-name-short) {
    display: none !important;
  }

  :global .navbar-inverse .navbar-nav #company-name {
    max-width: 120px;
  }
}

@media (width <= 768px) {
  :global .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #436b93;
  }

  :global .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #fff;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-weight: 300;
  }

  :is(:global .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, :global .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus) {
    color: #436b93;
    background-color: #0000;
  }

  :global .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a {
    color: #436b93;
    background-color: #0000;
  }

  :global .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover {
    color: #436b93;
    background-color: #0000;
  }

  :global .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #436b93;
    background-color: #0000;
  }

  :global .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a {
    color: #666;
    background-color: #0000;
  }

  :global .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover {
    color: #666;
    background-color: #0000;
  }

  :global .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #666;
    background-color: #0000;
  }

  :global .navbar-inverse .navbar-nav.user-label {
    max-width: initial;
  }

  :is(:global .navbar-inverse .navbar-nav .open li.dropdown-user-info > a:hover, :global .navbar-inverse .navbar-nav .open li.dropdown-user-info > a:focus) {
    color: #fff;
    cursor: default;
  }
}

:global .navbar-inverse .navbar-link {
  color: #fff;
}

:global .navbar-inverse .navbar-link:hover {
  color: #436b93;
}

:global .breadcrumb {
  background-color: #f3f3f3;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 8px 15px;
  list-style: none;
}

:global .breadcrumb > li {
  display: inline-block;
}

:global .breadcrumb > li + li:before {
  content: "/ ";
  color: #bbb;
  padding: 0 5px;
}

:global .breadcrumb > .active {
  color: #888;
}

:global .pagination {
  border-radius: 2px;
  margin: 20px 0;
  padding-left: 0;
  display: inline-block;
}

:global .pagination > li {
  display: inline;
}

:is(:global .pagination > li > a, :global .pagination > li > span) {
  float: left;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  padding: 6px 12px;
  line-height: 1.5;
  text-decoration: none;
  position: relative;
}

:is(:global .pagination > li:first-child > a, :global .pagination > li:first-child > span) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-left: 0;
}

:is(:global .pagination > li:last-child > a, :global .pagination > li:last-child > span) {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

:is(:global .pagination > li > a:hover, :global .pagination > li > a:focus, :global .pagination > li > span:hover, :global .pagination > li > span:focus) {
  background-color: #ddd;
}

:global .pagination > .active > a {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #436b93;
  border-color: #436b93;
}

:global .pagination > .active > a:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #436b93;
  border-color: #436b93;
}

:global .pagination > .active > a:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #436b93;
  border-color: #436b93;
}

:global .pagination > .active > span {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #436b93;
  border-color: #436b93;
}

:global .pagination > .active > span:hover {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #436b93;
  border-color: #436b93;
}

:global .pagination > .active > span:focus {
  z-index: 2;
  color: #fff;
  cursor: default;
  background-color: #436b93;
  border-color: #436b93;
}

:global .pagination > .disabled > span {
  color: #888;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

:global .pagination > .disabled > span:hover {
  color: #888;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

:global .pagination > .disabled > span:focus {
  color: #888;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

:global .pagination > .disabled > a {
  color: #888;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

:global .pagination > .disabled > a:hover {
  color: #888;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

:global .pagination > .disabled > a:focus {
  color: #888;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

:is(:global .pagination-lg > li > a, :global .pagination-lg > li > span) {
  padding: 12px 20px;
  font-size: 18px;
}

:is(:global .pagination-lg > li:first-child > a, :global .pagination-lg > li:first-child > span) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

:is(:global .pagination-lg > li:last-child > a, :global .pagination-lg > li:last-child > span) {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

:is(:global .pagination-sm > li > a, :global .pagination-sm > li > span) {
  padding: 5px 10px;
  font-size: 12px;
}

:is(:global .pagination-sm > li:first-child > a, :global .pagination-sm > li:first-child > span) {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

:is(:global .pagination-sm > li:last-child > a, :global .pagination-sm > li:last-child > span) {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

:global .pager {
  text-align: center;
  margin: 20px 0;
  padding-left: 0;
  list-style: none;
}

:global .pager:before {
  content: " ";
  display: table;
}

:global .pager:after {
  content: " ";
  display: table;
}

:global .pager:after {
  clear: both;
}

:global .pager li {
  display: inline;
}

:is(:global .pager li > a, :global .pager li > span) {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 5px 14px;
  display: inline-block;
}

:is(:global .pager li > a:hover, :global .pager li > a:focus) {
  background-color: #ddd;
  text-decoration: none;
}

:is(:global .pager .next > a, :global .pager .next > span) {
  float: right;
}

:is(:global .pager .previous > a, :global .pager .previous > span) {
  float: left;
}

:global .pager .disabled > a {
  color: #888;
  cursor: not-allowed;
  background-color: #fff;
}

:global .pager .disabled > a:hover {
  color: #888;
  cursor: not-allowed;
  background-color: #fff;
}

:global .pager .disabled > a:focus {
  color: #888;
  cursor: not-allowed;
  background-color: #fff;
}

:global .pager .disabled > span {
  color: #888;
  cursor: not-allowed;
  background-color: #fff;
}

:global .label {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  padding: .2em .6em .3em;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  display: inline;
}

:is(:global .label[href]:hover, :global .label[href]:focus) {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

:global .label:empty {
  display: none;
}

:global .label-default {
  background-color: #888;
}

:is(:global .label-default[href]:hover, :global .label-default[href]:focus) {
  background-color: #6f6f6f;
}

:global .label-primary {
  background-color: #436b93;
}

:is(:global .label-primary[href]:hover, :global .label-primary[href]:focus) {
  background-color: #335270;
}

:global .label-success {
  background-color: #66a559;
}

:is(:global .label-success[href]:hover, :global .label-success[href]:focus) {
  background-color: #528447;
}

:global .label-info {
  background-color: #436b93;
}

:is(:global .label-info[href]:hover, :global .label-info[href]:focus) {
  background-color: #335270;
}

:global .label-warning {
  background-color: #f07f04;
}

:is(:global .label-warning[href]:hover, :global .label-warning[href]:focus) {
  background-color: #be6403;
}

:global .label-danger {
  background-color: #c00;
}

:is(:global .label-danger[href]:hover, :global .label-danger[href]:focus) {
  background-color: #900;
}

:global .badge {
  color: #fff;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: #888;
  border-radius: 10px;
  min-width: 10px;
  padding: 3px 7px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
}

:global .badge:empty {
  display: none;
}

:is(:global a.badge:hover, :global a.badge:focus) {
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}

:global .btn .badge {
  position: relative;
  top: -1px;
}

:is(:global a.list-group-item.active > .badge, :global .nav-pills > .active > a > .badge) {
  color: #436b93;
  background-color: #fff;
}

:global .nav-pills > li > a > .badge {
  margin-left: 3px;
}

:global .badge-primary {
  background-color: #436b93;
}

:global .badge-success {
  background-color: #66a559;
}

:global .badge-info {
  background-color: #436b93;
}

:global .badge-warning {
  background-color: #f07f04;
}

:global .badge-danger {
  background-color: #c00;
}

:global .badge-active {
  color: #436b93;
  background-color: #fff;
}

:global .thumbnail {
  margin-bottom: 20px;
  display: block;
}

:global .thumbnail > img {
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

:global .thumbnail .caption {
  color: #222;
  padding: 9px;
}

:is(:global a.thumbnail:hover, :global a.thumbnail:focus, :global a.thumbnail.active) {
  border-color: #436b93;
}

:global .alert {
  border: 1px solid #0000;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 15px;
}

:global .alert h4 {
  color: inherit;
  margin-top: 0;
}

:global .alert .alert-link {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

:is(:global .alert > p, :global .alert > ul) {
  margin-bottom: 0;
}

:global .alert > p + p {
  margin-top: 5px;
}

:global .alert-dismissable {
  padding-right: 35px;
}

:global .alert-dismissable .close {
  color: inherit;
  position: relative;
  top: -2px;
  right: -21px;
}

:global .alert-success {
  color: #66a559;
  background-color: #b8e7c8;
  border-color: #66a559;
}

:global .alert-success hr {
  border-top-color: #5c9450;
}

:global .alert-success .alert-link {
  color: #528447;
}

:global .alert-info {
  color: #436b93;
  background-color: #d8ecf5;
  border-color: #d8ecf5;
}

:global .alert-info hr {
  border-top-color: #c4e2f0;
}

:global .alert-info .alert-link {
  color: #335270;
}

:global .alert-warning {
  color: #ffc100;
  background-color: #f3f3f3;
  border-color: #ffc100;
}

:global .alert-warning hr {
  border-top-color: #e6ae00;
}

:global .alert-warning .alert-link {
  color: #cc9a00;
}

:global .alert-todo {
  color: #222;
  background-color: #f3f3f3;
  border-color: #c00;
}

:global .alert-todo hr {
  border-top-color: #b30000;
}

:global .alert-todo .alert-link {
  color: #090909;
}

:is(:global .alert-danger, :global .alert-error) {
  color: #c00;
  background-color: #fbfbfb;
  border-color: #c00;
}

:is(:global .alert-danger hr, :global .alert-error hr) {
  border-top-color: #b30000;
}

:is(:global .alert-danger .alert-link, :global .alert-error .alert-link) {
  color: #900;
}

:global .alert-inverse {
  color: #ddd;
  background-color: #222;
  border-color: #888;
}

:global .alert-inverse hr {
  border-top-color: #7b7b7b;
}

:global .alert-inverse .alert-link {
  color: #c4c4c4;
}

:global #dibs-nav {
  z-index: 1020;
  width: 100%;
  height: 0;
  position: fixed;
}

:global #dibs-nav .page-message {
  width: 700px;
  position: relative;
  top: 65px;
  box-shadow: 0 2px 3px #ddd;
}

:global .page-message {
  max-width: 80%;
  margin: 0 auto;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 40px 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

:global .progress {
  background-color: #f3f3f3;
  border-radius: 2px;
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: inset 0 1px 2px #2222221a;
}

:global .progress-bar {
  float: left;
  color: #fff;
  text-align: center;
  background-color: #436b93;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  transition: width .6s;
  box-shadow: inset 0 -1px #22222226;
}

:global .progress-striped .progress-bar {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 40px 40px;
}

:global .progress.active .progress-bar {
  animation: 2s linear infinite progress-bar-stripes;
}

:global .progress-bar-success {
  background-color: #66a559;
}

.progress-striped :global .progress-bar-success {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

:global .progress-bar-info {
  background-color: #436b93;
}

.progress-striped :global .progress-bar-info {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

:global .progress-bar-warning {
  background-color: #f07f04;
}

.progress-striped :global .progress-bar-warning {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

:global .progress-bar-danger {
  background-color: #c00;
}

.progress-striped :global .progress-bar-danger {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
}

:is(:global .media, :global .media-body) {
  zoom: 1;
  overflow: hidden;
}

:global .media {
  margin-top: 15px;
}

:global .media .media {
  margin-top: 15px;
}

:global .media:first-child {
  margin-top: 0;
}

:global .media-object {
  display: block;
}

:global .media-heading {
  margin: 0 0 5px;
}

:global .media > .pull-left {
  margin-right: 10px;
}

:global .media > .pull-right {
  margin-left: 10px;
}

:global .media-list {
  padding-left: 0;
  list-style: none;
}

:global .list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

:global .list-group-item {
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: -1px;
  padding: 10px 15px;
  display: block;
  position: relative;
}

:global .list-group-item:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

:global .list-group-item:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-bottom: 0;
}

:global .list-group-item > .badge {
  float: right;
}

:global .list-group-item > .badge + .badge {
  margin-right: 5px;
}

:global a.list-group-item {
  color: #666;
}

:global a.list-group-item .list-group-item-heading {
  color: #222;
}

:is(:global a.list-group-item:hover, :global a.list-group-item:focus) {
  background-color: #f3f3f3;
  text-decoration: none;
}

:global a.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #436b93;
  border-color: #436b93;
}

:global a.list-group-item.active:hover {
  z-index: 2;
  color: #fff;
  background-color: #436b93;
  border-color: #436b93;
}

:global a.list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #436b93;
  border-color: #436b93;
}

:global a.list-group-item.active .list-group-item-heading {
  color: inherit;
}

:global a.list-group-item.active:hover .list-group-item-heading {
  color: inherit;
}

:global a.list-group-item.active:focus .list-group-item-heading {
  color: inherit;
}

:global a.list-group-item.active .list-group-item-text {
  color: #c0d1e2;
}

:global a.list-group-item.active:hover .list-group-item-text {
  color: #c0d1e2;
}

:global a.list-group-item.active:focus .list-group-item-text {
  color: #c0d1e2;
}

:global .list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

:global .list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

:global .panel {
  background-color: #fff;
  border: 1px solid #0000;
  border-radius: 2px;
  margin-bottom: 20px;
  box-shadow: 0 1px 1px #2222220d;
}

:global .panel-body {
  padding: 15px;
}

:global .panel-body:before {
  content: " ";
  display: table;
}

:global .panel-body:after {
  content: " ";
  display: table;
}

:global .panel-body:after {
  clear: both;
}

:global .panel > .list-group {
  margin-bottom: 0;
}

:global .panel > .list-group .list-group-item {
  border-width: 1px 0;
}

:global .panel > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

:global .panel > .list-group .list-group-item:last-child {
  border-bottom: 0;
}

:global .panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}

:is(:global .panel > .table, :global .panel > .table-responsive) {
  margin-bottom: 0;
}

:is(:global .panel > .panel-body + .table, :global .panel > .panel-body + .table-responsive) {
  border-top: 1px solid #ddd;
}

:global .panel > .table-bordered {
  border: 0;
}

:global .panel > .table-responsive > .table-bordered {
  border: 0;
}

:global .panel > .table-bordered > thead > tr > th:first-child {
  border-left: 0;
}

:global .panel > .table-bordered > thead > tr > td:first-child {
  border-left: 0;
}

:global .panel > .table-bordered > tbody > tr > th:first-child {
  border-left: 0;
}

:global .panel > .table-bordered > tbody > tr > td:first-child {
  border-left: 0;
}

:global .panel > .table-bordered > tfoot > tr > th:first-child {
  border-left: 0;
}

:global .panel > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

:global .panel > .table-responsive > .table-bordered > thead > tr > th:first-child {
  border-left: 0;
}

:global .panel > .table-responsive > .table-bordered > thead > tr > td:first-child {
  border-left: 0;
}

:global .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child {
  border-left: 0;
}

:global .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child {
  border-left: 0;
}

:global .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child {
  border-left: 0;
}

:global .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}

:global .panel > .table-bordered > thead > tr > th:last-child {
  border-right: 0;
}

:global .panel > .table-bordered > thead > tr > td:last-child {
  border-right: 0;
}

:global .panel > .table-bordered > tbody > tr > th:last-child {
  border-right: 0;
}

:global .panel > .table-bordered > tbody > tr > td:last-child {
  border-right: 0;
}

:global .panel > .table-bordered > tfoot > tr > th:last-child {
  border-right: 0;
}

:global .panel > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

:global .panel > .table-responsive > .table-bordered > thead > tr > th:last-child {
  border-right: 0;
}

:global .panel > .table-responsive > .table-bordered > thead > tr > td:last-child {
  border-right: 0;
}

:global .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child {
  border-right: 0;
}

:global .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child {
  border-right: 0;
}

:global .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child {
  border-right: 0;
}

:global .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}

:global .panel > .table-bordered > thead > tr:last-child > th {
  border-bottom: 0;
}

:global .panel > .table-bordered > thead > tr:last-child > td {
  border-bottom: 0;
}

:global .panel > .table-bordered > tbody > tr:last-child > th {
  border-bottom: 0;
}

:global .panel > .table-bordered > tbody > tr:last-child > td {
  border-bottom: 0;
}

:global .panel > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

:global .panel > .table-bordered > tfoot > tr:last-child > td {
  border-bottom: 0;
}

:global .panel > .table-responsive > .table-bordered > thead > tr:last-child > th {
  border-bottom: 0;
}

:global .panel > .table-responsive > .table-bordered > thead > tr:last-child > td {
  border-bottom: 0;
}

:global .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th {
  border-bottom: 0;
}

:global .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td {
  border-bottom: 0;
}

:global .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

:global .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td {
  border-bottom: 0;
}

:global .panel-heading {
  border-bottom: 1px solid #0000;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  padding: 10px 15px;
}

:global .panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

:global .panel-heading-sm {
  font-size: 12px;
}

:global .panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

:global .panel-title > a {
  color: inherit;
}

:global .panel-footer {
  background-color: #f3f3f3;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
  padding: 10px 15px;
}

:global .panel-group .panel {
  border-radius: 2px;
  margin-bottom: 0;
  overflow: hidden;
}

:global .panel-group .panel + .panel {
  margin-top: 5px;
}

:global .panel-group .panel-heading {
  border-bottom: 0;
}

:global .panel-group .panel-heading + .panel-collapse .panel-body {
  border-top: 1px solid #ddd;
}

:global .panel-group .panel-footer {
  border-top: 0;
}

:global .panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

:global .panel-default {
  border-color: #ddd;
}

:global .panel-default > .panel-heading {
  color: #222;
  background-color: #fff;
  border-color: #ddd;
}

:global .panel-default > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #ddd;
}

:global .panel-default > .panel-heading > .dropdown .caret {
  border-color: #222 #0000;
}

:global .panel-default > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #ddd;
}

:global .panel-primary {
  border-color: #436b93;
}

:global .panel-primary > .panel-heading {
  color: #fff;
  background-color: #436b93;
  border-color: #436b93;
}

:global .panel-primary > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #436b93;
}

:global .panel-primary > .panel-heading > .dropdown .caret {
  border-color: #fff #0000;
}

:global .panel-primary > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #436b93;
}

:global .panel-success {
  border-color: #66a559;
}

:global .panel-success > .panel-heading {
  color: #66a559;
  background-color: #b8e7c8;
  border-color: #66a559;
}

:global .panel-success > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #66a559;
}

:global .panel-success > .panel-heading > .dropdown .caret {
  border-color: #66a559 #0000;
}

:global .panel-success > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #66a559;
}

:global .panel-warning {
  border-color: #ffc100;
}

:global .panel-warning > .panel-heading {
  color: #ffc100;
  background-color: #f3f3f3;
  border-color: #ffc100;
}

:global .panel-warning > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #ffc100;
}

:global .panel-warning > .panel-heading > .dropdown .caret {
  border-color: #ffc100 #0000;
}

:global .panel-warning > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #ffc100;
}

:global .panel-danger {
  border-color: #c00;
}

:global .panel-danger > .panel-heading {
  color: #c00;
  background-color: #fff;
  border-color: #c00;
}

:global .panel-danger > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #c00;
}

:global .panel-danger > .panel-heading > .dropdown .caret {
  border-color: #c00 #0000;
}

:global .panel-danger > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #c00;
}

:global .panel-info {
  border-color: #d8ecf5;
}

:global .panel-info > .panel-heading {
  color: #436b93;
  background-color: #d8ecf5;
  border-color: #d8ecf5;
}

:global .panel-info > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #d8ecf5;
}

:global .panel-info > .panel-heading > .dropdown .caret {
  border-color: #436b93 #0000;
}

:global .panel-info > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #d8ecf5;
}

:global .well {
  background-color: #fbfbfb;
  border: 1px solid #f3f3f3;
  border-radius: 2px;
  min-height: 20px;
  margin-bottom: 20px;
  padding: 19px;
  box-shadow: inset 0 1px 1px #2222220d;
}

:global .well blockquote {
  border-color: #22222226;
}

:global .well .well-heading {
  border-radius: 2px 2px 0 0;
  margin: -19px -19px 20px;
  padding: 19px;
}

:global .well.well-white {
  background-color: #fff;
}

:global .well-heading {
  background-color: #fbfbfb;
  border-bottom: 1px solid #f3f3f3;
}

:global .well-lg {
  border-radius: 2px;
  padding: 29px;
}

:global .well-lg .well-heading {
  border-radius: 2px 2px 0 0;
  margin: -29px -29px 30px;
  padding: 29px;
}

:global .well-sm {
  border-radius: 2px;
  padding: 14px;
}

:global .well-sm .well-heading {
  border-radius: 2px 2px 0 0;
  margin: -14px -14px 15px;
  padding: 14px;
}

:global .close {
  float: right;
  color: #888;
  opacity: .2;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}

:is(:global .close:hover, :global .close:focus) {
  color: #222;
  cursor: pointer;
  opacity: .5;
  text-decoration: none;
}

:global button.close {
  cursor: pointer;
  -webkit-appearance: none;
  background: none;
  border: 0;
  padding: 0;
}

:global .flexbox-row {
  flex-direction: row;
  display: flex;
}

:global .flexbox-row-reverse {
  flex-direction: row-reverse;
  display: flex;
}

:global .flexbox-column {
  flex-direction: column;
  display: flex;
}

:global .flexbox-column-reverse {
  flex-direction: column-reverse;
  display: flex;
}

:global .flex-1 {
  flex: 1 1 0;
}

:global .flex-2 {
  flex: 2 1 0;
}

:global .flex-3 {
  flex: 3 1 0;
}

:global .flex-4 {
  flex: 4 1 0;
}

:global .overflow-y-scroll {
  overflow-y: scroll;
}

:global .modal-open {
  overflow: hidden;
}

:global .modal {
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

:global .modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -25%);
}

:global .modal.in .modal-dialog {
  transform: translate(0);
}

:global .modal-open .modal {
  overflow: hidden auto;
}

:global .modal-dialog {
  width: auto;
  margin: 10px;
  position: relative;
}

:global .modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #888;
  border-radius: 2px;
  outline: 0;
  position: relative;
  box-shadow: 0 3px 9px #000000b3;
}

:global .modal-backdrop {
  z-index: 1040;
  background-color: #222;
  position: fixed;
  inset: 0;
}

:global .modal-backdrop.fade {
  opacity: 0;
}

:global .modal-backdrop.in {
  opacity: .5;
}

:global .modal-header {
  border-bottom: 1px solid #ddd;
  min-height: 10.5px;
  padding: 9px;
}

:global .modal-header .close {
  margin-top: 4px;
  padding: 3px;
}

:global .modal-title {
  margin: 0;
  line-height: 1.5;
}

:global .modal-body {
  padding: 20px;
  position: relative;
}

:global .modal-footer {
  text-align: right;
  border-top: 1px solid #ddd;
  padding: 20px;
}

:global .modal-footer:before {
  content: " ";
  display: table;
}

:global .modal-footer:after {
  content: " ";
  display: table;
}

:global .modal-footer:after {
  clear: both;
}

:global .modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

:global .modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

:global .modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

:global .modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (width >= 769px) {
  :global .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  :global .modal-content {
    box-shadow: 0 5px 15px #000000b3;
  }

  :global .modal-sm {
    width: 300px;
  }

  :global .modal-lg {
    width: 900px;
  }
}

:global .modal-rail {
  background-color: #fbfbfb;
  border-bottom: 1px solid #ddd;
  margin: -20px -20px 20px;
  padding: 10px 20px;
  font-size: 12px;
}

:global .tooltip {
  z-index: 1100;
  visibility: visible;
  opacity: 0;
  font-size: 12px;
  line-height: 1.5;
  display: block;
  position: absolute;
}

:global .tooltip.in {
  opacity: .9;
}

:global .tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}

:global .tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}

:global .tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}

:global .tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

:global .tooltip-inner {
  color: #fff;
  text-align: center;
  background-color: #222;
  border-radius: 2px;
  max-width: 200px;
  padding: 3px 8px;
  text-decoration: none;
}

:global .tooltip-arrow {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  position: absolute;
}

:global .tooltip.top .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #222;
  margin-left: -5px;
  bottom: 0;
  left: 50%;
}

:global .tooltip.top-left .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #222;
  bottom: 0;
  left: 5px;
}

:global .tooltip.top-right .tooltip-arrow {
  border-width: 5px 5px 0;
  border-top-color: #222;
  bottom: 0;
  right: 5px;
}

:global .tooltip.right .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-right-color: #222;
  margin-top: -5px;
  top: 50%;
  left: 0;
}

:global .tooltip.left .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-left-color: #222;
  margin-top: -5px;
  top: 50%;
  right: 0;
}

:global .tooltip.bottom .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #222;
  margin-left: -5px;
  top: 0;
  left: 50%;
}

:global .tooltip.bottom-left .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #222;
  top: 0;
  left: 5px;
}

:global .tooltip.bottom-right .tooltip-arrow {
  border-width: 0 5px 5px;
  border-bottom-color: #222;
  top: 0;
  right: 5px;
}

:global .popover {
  z-index: 1100;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bbb;
  border-radius: 2px;
  max-width: 500px;
  padding: 1px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 5px 10px #2223;
}

:global .popover.top {
  margin-top: -10px;
}

:global .popover.right {
  margin-left: 10px;
}

:global .popover.bottom {
  margin-top: 10px;
}

:global .popover.left {
  margin-left: -10px;
}

:global .popover-title {
  background-color: #fbfbfb;
  border-bottom: 1px solid #ddd;
  border-radius: 5px 5px 0 0;
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  line-height: 18px;
}

:global .popover-content {
  padding: 9px 14px;
}

:global .popover .arrow {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

:global .popover .arrow:after {
  border-style: solid;
  border-color: #0000;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
}

:global .popover .arrow {
  border-width: 11px;
}

:global .popover .arrow:after {
  content: "";
  border-width: 10px;
}

:global .popover.top .arrow {
  border-top-color: #22222240;
  border-bottom-width: 0;
  margin-left: -11px;
  bottom: -11px;
  left: 50%;
}

:global .popover.top .arrow:after {
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
  margin-left: -10px;
  bottom: 1px;
}

:global .popover.right .arrow {
  border-left-width: 0;
  border-right-color: #22222240;
  margin-top: -11px;
  top: 50%;
  left: -11px;
}

:global .popover.right .arrow:after {
  content: " ";
  border-left-width: 0;
  border-right-color: #fff;
  bottom: -10px;
  left: 1px;
}

:global .popover.bottom .arrow {
  border-top-width: 0;
  border-bottom-color: #22222240;
  margin-left: -11px;
  top: -11px;
  left: 50%;
}

:global .popover.bottom .arrow:after {
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
  margin-left: -10px;
  top: 1px;
}

:global .popover.left .arrow {
  border-left-color: #22222240;
  border-right-width: 0;
  margin-top: -11px;
  top: 50%;
  right: -11px;
}

:global .popover.left .arrow:after {
  content: " ";
  border-left-color: #fff;
  border-right-width: 0;
  bottom: -10px;
  right: 1px;
}

:global .carousel {
  position: relative;
}

:global .carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

:global .carousel-inner > .item {
  transition: left .6s ease-in-out;
  display: none;
  position: relative;
}

:global .carousel-inner > .item > img {
  max-width: 100%;
  height: auto;
  line-height: 1;
  display: block;
}

:global .carousel-inner > .item > a > img {
  max-width: 100%;
  height: auto;
  line-height: 1;
  display: block;
}

:is(:global .carousel-inner > .active, :global .carousel-inner > .next, :global .carousel-inner > .prev) {
  display: block;
}

:global .carousel-inner > .active {
  left: 0;
}

:is(:global .carousel-inner > .next, :global .carousel-inner > .prev) {
  width: 100%;
  position: absolute;
  top: 0;
}

:global .carousel-inner > .next {
  left: 100%;
}

:global .carousel-inner > .prev {
  left: -100%;
}

:is(:global .carousel-inner > .next.left, :global .carousel-inner > .prev.right) {
  left: 0;
}

:global .carousel-inner > .active.left {
  left: -100%;
}

:global .carousel-inner > .active.right {
  left: 100%;
}

:global .carousel-control {
  opacity: .5;
  color: #fff;
  text-align: center;
  width: 15%;
  text-shadow: #000000b3;
  font-size: 18px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

:global .carousel-control.left {
  background-image: linear-gradient(to right, #22222280 0%, #2220 100%);
  background-repeat: repeat-x;
}

:global .carousel-control.right {
  background-image: linear-gradient(to right, #2220 0%, #22222280 100%);
  background-repeat: repeat-x;
  left: auto;
  right: 0;
}

:is(:global .carousel-control:hover, :global .carousel-control:focus) {
  color: #fff;
  opacity: .9;
  text-decoration: none;
}

:is(:global .carousel-control .icon-prev, :global .carousel-control .icon-next, :global .carousel-control .glyphicon-chevron-left, :global .carousel-control .glyphicon-chevron-right) {
  z-index: 5;
  display: inline-block;
  position: absolute;
  top: 50%;
}

:is(:global .carousel-control .icon-prev, :global .carousel-control .glyphicon-chevron-left) {
  left: 50%;
}

:is(:global .carousel-control .icon-next, :global .carousel-control .glyphicon-chevron-right) {
  right: 50%;
}

:is(:global .carousel-control .icon-prev, :global .carousel-control .icon-next) {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-style: normal;
  font-weight: normal;
}

:global .carousel-control .icon-prev:before {
  content: "‹";
}

:global .carousel-control .icon-next:before {
  content: "›";
}

:global .carousel-indicators {
  z-index: 15;
  text-align: center;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  position: absolute;
  bottom: 10px;
  left: 50%;
}

:global .carousel-indicators li {
  text-indent: -999px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin: 1px;
  display: inline-block;
}

:global .carousel-indicators .active {
  background-color: #fff;
  width: 12px;
  height: 12px;
  margin: 0;
}

:global .carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  text-shadow: #000000b3;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

:global .carousel-caption .btn {
  text-shadow: none;
}

@media (width >= 769px) {
  :is(:global .carousel-control .glyphicons-chevron-left, :global .carousel-control .glyphicons-chevron-right, :global .carousel-control .icon-prev, :global .carousel-control .icon-next) {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    font-size: 30px;
  }

  :global .carousel-caption {
    padding-bottom: 30px;
    left: 20%;
    right: 20%;
  }

  :global .carousel-indicators {
    bottom: 20px;
  }
}

:global .clearfix:before {
  content: " ";
  display: table;
}

:global .clearfix:after {
  content: " ";
  display: table;
}

:global .clearfix:after {
  clear: both;
}

:global .center-block {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

:is(:global .pull-right, :global .right, :global .fr) {
  float: right !important;
}

:is(:global .pull-left, :global .left, :global .fl) {
  float: left !important;
}

:global .hide {
  display: none !important;
}

:global .show {
  display: block !important;
}

:global .invisible {
  visibility: hidden;
}

:global .text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

:global .hidden {
  visibility: hidden !important;
  display: none !important;
}

:global .affix {
  position: fixed;
}

:global .no-spacing {
  margin: 0 !important;
  padding: 0 !important;
}

:global .no-margin {
  margin: 0 !important;
}

:global .no-side-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

:global .no-padd {
  padding: 0 !important;
}

:is(:global .no-side-padd, :global .btn-link-no-spacing) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:global .no-border {
  border: 0 !important;
}

:is(:global .no-min-width, :global .btn-link-no-spacing) {
  min-width: 0 !important;
}

:global .force-inline {
  display: inline-block !important;
}

@-ms-viewport {
}

@media (width <= 568px) {
  :global .fullw-below-sm {
    width: 100% !important;
    max-width: 100% !important;
  }

  :global .clear-below-sm {
    clear: both;
  }
}

@media (width <= 768px) {
  :global .fullw-below-md {
    width: 100% !important;
    max-width: 100% !important;
  }

  :global .clear-below-md {
    clear: both;
  }
}

@media (width <= 568px) {
  :global .hide-below-xs {
    display: none !important;
  }

  :global .row-below-xs {
    margin-left: -15px;
    margin-right: -15px;
  }

  :global .row-below-xs:before {
    content: " ";
    display: table;
  }

  :global .row-below-xs:after {
    content: " ";
    display: table;
  }

  :global .row-below-xs:after {
    clear: both;
  }
}

@media (width <= 768px) {
  :global .hide-below-sm {
    display: none !important;
  }

  :global .row-below-sm {
    margin-left: -15px;
    margin-right: -15px;
  }

  :global .row-below-sm:before {
    content: " ";
    display: table;
  }

  :global .row-below-sm:after {
    content: " ";
    display: table;
  }

  :global .row-below-sm:after {
    clear: both;
  }
}

@media (width <= 1024px) {
  :global .hide-below-md {
    display: none !important;
  }

  :global .row-below-md {
    margin-left: -15px;
    margin-right: -15px;
  }

  :global .row-below-md:before {
    content: " ";
    display: table;
  }

  :global .row-below-md:after {
    content: " ";
    display: table;
  }

  :global .row-below-md:after {
    clear: both;
  }
}

:global .hide-below-lg {
  display: none !important;
}

:global .row-below-lg {
  margin-left: -15px;
  margin-right: -15px;
}

:global .row-below-lg:before {
  content: " ";
  display: table;
}

:global .row-below-lg:after {
  content: " ";
  display: table;
}

:global .row-below-lg:after {
  clear: both;
}

@media (width >= 1025px) {
  :global .hide-above-md {
    display: none !important;
  }

  :global .row-above-md {
    margin-left: -15px;
    margin-right: -15px;
  }

  :global .row-above-md:before {
    content: " ";
    display: table;
  }

  :global .row-above-md:after {
    content: " ";
    display: table;
  }

  :global .row-above-md:after {
    clear: both;
  }
}

@media (width >= 769px) {
  :global .hide-above-sm {
    display: none !important;
  }

  :global .row-above-sm {
    margin-left: -15px;
    margin-right: -15px;
  }

  :global .row-above-sm:before {
    content: " ";
    display: table;
  }

  :global .row-above-sm:after {
    content: " ";
    display: table;
  }

  :global .row-above-sm:after {
    clear: both;
  }
}

@media (width >= 569px) {
  :global .hide-above-xs {
    display: none !important;
  }

  :global .row-above-xs {
    margin-left: -15px;
    margin-right: -15px;
  }

  :global .row-above-xs:before {
    content: " ";
    display: table;
  }

  :global .row-above-xs:after {
    content: " ";
    display: table;
  }

  :global .row-above-xs:after {
    clear: both;
  }
}

:global .visible-xs {
  display: none !important;
}

:global tr.visible-xs {
  display: none !important;
}

:global th.visible-xs {
  display: none !important;
}

:global td.visible-xs {
  display: none !important;
}

:global .visible-sm {
  display: none !important;
}

:global tr.visible-sm {
  display: none !important;
}

:global th.visible-sm {
  display: none !important;
}

:global td.visible-sm {
  display: none !important;
}

:global .visible-md {
  display: none !important;
}

:global tr.visible-md {
  display: none !important;
}

:global th.visible-md {
  display: none !important;
}

:global td.visible-md {
  display: none !important;
}

:global .visible-lg {
  display: none !important;
}

:global tr.visible-lg {
  display: none !important;
}

:global th.visible-lg {
  display: none !important;
}

:global td.visible-lg {
  display: none !important;
}

:global .hidden-xs {
  display: inherit !important;
}

:global tr.hidden-xs {
  display: table-row !important;
}

:is(:global th.hidden-xs, :global td.hidden-xs) {
  display: table-cell !important;
}

:global .hidden-sm {
  display: inherit !important;
}

:global tr.hidden-sm {
  display: table-row !important;
}

:is(:global th.hidden-sm, :global td.hidden-sm) {
  display: table-cell !important;
}

:global .hidden-md {
  display: inherit !important;
}

:global tr.hidden-md {
  display: table-row !important;
}

:is(:global th.hidden-md, :global td.hidden-md) {
  display: table-cell !important;
}

:global .hidden-lg {
  display: inherit !important;
}

:global tr.hidden-lg {
  display: table-row !important;
}

:is(:global th.hidden-lg, :global td.hidden-lg) {
  display: table-cell !important;
}

@media (width <= 568px) {
  :global .no-side-padd-xs {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  :global .no-side-marg-xs {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  :global .visible-xs {
    display: inherit !important;
  }

  :global tr.visible-xs {
    display: table-row !important;
  }

  :is(:global th.visible-xs, :global td.visible-xs) {
    display: table-cell !important;
  }

  :global .visible-sm.visible-xs {
    display: inherit !important;
  }

  :global tr.visible-sm.visible-xs {
    display: table-row !important;
  }

  :is(:global th.visible-sm.visible-xs, :global td.visible-sm.visible-xs) {
    display: table-cell !important;
  }

  :global .visible-md.visible-xs {
    display: inherit !important;
  }

  :global tr.visible-md.visible-xs {
    display: table-row !important;
  }

  :is(:global th.visible-md.visible-xs, :global td.visible-md.visible-xs) {
    display: table-cell !important;
  }

  :global .visible-lg.visible-xs {
    display: inherit !important;
  }

  :global tr.visible-lg.visible-xs {
    display: table-row !important;
  }

  :is(:global th.visible-lg.visible-xs, :global td.visible-lg.visible-xs) {
    display: table-cell !important;
  }

  :global .hidden-xs {
    display: none !important;
  }

  :global tr.hidden-xs {
    display: none !important;
  }

  :global th.hidden-xs {
    display: none !important;
  }

  :global td.hidden-xs {
    display: none !important;
  }

  :global .hidden-sm.hidden-xs {
    display: none !important;
  }

  :global tr.hidden-sm.hidden-xs {
    display: none !important;
  }

  :global th.hidden-sm.hidden-xs {
    display: none !important;
  }

  :global td.hidden-sm.hidden-xs {
    display: none !important;
  }

  :global .hidden-md.hidden-xs {
    display: none !important;
  }

  :global tr.hidden-md.hidden-xs {
    display: none !important;
  }

  :global th.hidden-md.hidden-xs {
    display: none !important;
  }

  :global td.hidden-md.hidden-xs {
    display: none !important;
  }

  :global .hidden-lg.hidden-xs {
    display: none !important;
  }

  :global tr.hidden-lg.hidden-xs {
    display: none !important;
  }

  :global th.hidden-lg.hidden-xs {
    display: none !important;
  }

  :global td.hidden-lg.hidden-xs {
    display: none !important;
  }
}

@media (width >= 569px) and (width <= 768px) {
  :global .no-side-padd-sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  :global .no-side-marg-sm {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  :global .visible-xs.visible-sm {
    display: inherit !important;
  }

  :global tr.visible-xs.visible-sm {
    display: table-row !important;
  }

  :is(:global th.visible-xs.visible-sm, :global td.visible-xs.visible-sm) {
    display: table-cell !important;
  }

  :global .visible-sm {
    display: inherit !important;
  }

  :global tr.visible-sm {
    display: table-row !important;
  }

  :is(:global th.visible-sm, :global td.visible-sm) {
    display: table-cell !important;
  }

  :global .visible-md.visible-sm {
    display: inherit !important;
  }

  :global tr.visible-md.visible-sm {
    display: table-row !important;
  }

  :is(:global th.visible-md.visible-sm, :global td.visible-md.visible-sm) {
    display: table-cell !important;
  }

  :global .visible-lg.visible-sm {
    display: inherit !important;
  }

  :global tr.visible-lg.visible-sm {
    display: table-row !important;
  }

  :is(:global th.visible-lg.visible-sm, :global td.visible-lg.visible-sm) {
    display: table-cell !important;
  }

  :global .hidden-xs.hidden-sm {
    display: none !important;
  }

  :global tr.hidden-xs.hidden-sm {
    display: none !important;
  }

  :global th.hidden-xs.hidden-sm {
    display: none !important;
  }

  :global td.hidden-xs.hidden-sm {
    display: none !important;
  }

  :global .hidden-sm {
    display: none !important;
  }

  :global tr.hidden-sm {
    display: none !important;
  }

  :global th.hidden-sm {
    display: none !important;
  }

  :global td.hidden-sm {
    display: none !important;
  }

  :global .hidden-md.hidden-sm {
    display: none !important;
  }

  :global tr.hidden-md.hidden-sm {
    display: none !important;
  }

  :global th.hidden-md.hidden-sm {
    display: none !important;
  }

  :global td.hidden-md.hidden-sm {
    display: none !important;
  }

  :global .hidden-lg.hidden-sm {
    display: none !important;
  }

  :global tr.hidden-lg.hidden-sm {
    display: none !important;
  }

  :global th.hidden-lg.hidden-sm {
    display: none !important;
  }

  :global td.hidden-lg.hidden-sm {
    display: none !important;
  }
}

@media (width >= 769px) and (width <= 1024px) {
  :global .no-side-padd-md {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  :global .no-side-marg-md {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  :global .visible-xs.visible-md {
    display: inherit !important;
  }

  :global tr.visible-xs.visible-md {
    display: table-row !important;
  }

  :is(:global th.visible-xs.visible-md, :global td.visible-xs.visible-md) {
    display: table-cell !important;
  }

  :global .visible-sm.visible-md {
    display: inherit !important;
  }

  :global tr.visible-sm.visible-md {
    display: table-row !important;
  }

  :is(:global th.visible-sm.visible-md, :global td.visible-sm.visible-md) {
    display: table-cell !important;
  }

  :global .visible-md {
    display: inherit !important;
  }

  :global tr.visible-md {
    display: table-row !important;
  }

  :is(:global th.visible-md, :global td.visible-md) {
    display: table-cell !important;
  }

  :global .visible-lg.visible-md {
    display: inherit !important;
  }

  :global tr.visible-lg.visible-md {
    display: table-row !important;
  }

  :is(:global th.visible-lg.visible-md, :global td.visible-lg.visible-md) {
    display: table-cell !important;
  }

  :global .hidden-xs.hidden-md {
    display: none !important;
  }

  :global tr.hidden-xs.hidden-md {
    display: none !important;
  }

  :global th.hidden-xs.hidden-md {
    display: none !important;
  }

  :global td.hidden-xs.hidden-md {
    display: none !important;
  }

  :global .hidden-sm.hidden-md {
    display: none !important;
  }

  :global tr.hidden-sm.hidden-md {
    display: none !important;
  }

  :global th.hidden-sm.hidden-md {
    display: none !important;
  }

  :global td.hidden-sm.hidden-md {
    display: none !important;
  }

  :global .hidden-md {
    display: none !important;
  }

  :global tr.hidden-md {
    display: none !important;
  }

  :global th.hidden-md {
    display: none !important;
  }

  :global td.hidden-md {
    display: none !important;
  }

  :global .hidden-lg.hidden-md {
    display: none !important;
  }

  :global tr.hidden-lg.hidden-md {
    display: none !important;
  }

  :global th.hidden-lg.hidden-md {
    display: none !important;
  }

  :global td.hidden-lg.hidden-md {
    display: none !important;
  }
}

@media (width >= 1025px) {
  :global .no-side-padd-lg {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  :global .no-side-marg-lg {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  :global .visible-xs.visible-lg {
    display: inherit !important;
  }

  :global tr.visible-xs.visible-lg {
    display: table-row !important;
  }

  :is(:global th.visible-xs.visible-lg, :global td.visible-xs.visible-lg) {
    display: table-cell !important;
  }

  :global .visible-sm.visible-lg {
    display: inherit !important;
  }

  :global tr.visible-sm.visible-lg {
    display: table-row !important;
  }

  :is(:global th.visible-sm.visible-lg, :global td.visible-sm.visible-lg) {
    display: table-cell !important;
  }

  :global .visible-md.visible-lg {
    display: inherit !important;
  }

  :global tr.visible-md.visible-lg {
    display: table-row !important;
  }

  :is(:global th.visible-md.visible-lg, :global td.visible-md.visible-lg) {
    display: table-cell !important;
  }

  :global .visible-lg {
    display: inherit !important;
  }

  :global tr.visible-lg {
    display: table-row !important;
  }

  :is(:global th.visible-lg, :global td.visible-lg) {
    display: table-cell !important;
  }

  :global .hidden-xs.hidden-lg {
    display: none !important;
  }

  :global tr.hidden-xs.hidden-lg {
    display: none !important;
  }

  :global th.hidden-xs.hidden-lg {
    display: none !important;
  }

  :global td.hidden-xs.hidden-lg {
    display: none !important;
  }

  :global .hidden-sm.hidden-lg {
    display: none !important;
  }

  :global tr.hidden-sm.hidden-lg {
    display: none !important;
  }

  :global th.hidden-sm.hidden-lg {
    display: none !important;
  }

  :global td.hidden-sm.hidden-lg {
    display: none !important;
  }

  :global .hidden-md.hidden-lg {
    display: none !important;
  }

  :global tr.hidden-md.hidden-lg {
    display: none !important;
  }

  :global th.hidden-md.hidden-lg {
    display: none !important;
  }

  :global td.hidden-md.hidden-lg {
    display: none !important;
  }

  :global .hidden-lg {
    display: none !important;
  }

  :global tr.hidden-lg {
    display: none !important;
  }

  :global th.hidden-lg {
    display: none !important;
  }

  :global td.hidden-lg {
    display: none !important;
  }
}

:global .visible-print {
  display: none !important;
}

:global tr.visible-print {
  display: none !important;
}

:global th.visible-print {
  display: none !important;
}

:global td.visible-print {
  display: none !important;
}

@media print {
  :global .visible-print {
    display: inherit !important;
  }

  :global tr.visible-print {
    display: table-row !important;
  }

  :is(:global th.visible-print, :global td.visible-print) {
    display: table-cell !important;
  }

  :global .hidden-print {
    display: none !important;
  }

  :global tr.hidden-print {
    display: none !important;
  }

  :global th.hidden-print {
    display: none !important;
  }

  :global td.hidden-print {
    display: none !important;
  }
}

:global #dibs-popup-frame.dibs-popup {
  background-color: #fff;
  max-width: 976px;
  max-height: 90%;
  padding: 10px;
}

:global .dibs-popup .form-horizontal {
  margin: 0;
}

:global .dibs-popup .form-horizontal .message img {
  margin-right: 20px;
}

:global .dibs-popup .form-horizontal .message {
  padding: .5em;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-weight: 600;
}

:global .dibs-popup .form-group {
  margin-top: 10px;
}

:global .dibs-popup .form-horizontal .modal-footer .controls {
  text-align: right;
}

:global .dibs-popup .info-wrapper .form-group {
  text-align: right;
}

:global .modal-open {
  margin-right: 15px;
  overflow: hidden;
}

:global .modal-open .navbar .nav.pull-right {
  margin-right: 15px;
}

:is(:global #sidebar-inner, :global #body-inner) {
  background-color: #fff;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

:global #sidebar-inner {
  background-color: #fff;
  margin-left: 10px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
}

:global #body-inner {
  margin-left: -1%;
  margin-right: 10px;
}

:global #sidebar-inner h2 {
  text-transform: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

:global #sidebar-inner ul li {
  list-style: none;
}

:global #sidebar-inner ul li a {
  color: #222;
}

:global #sidebar-inner ul li a:hover {
  color: #c00;
}

:global #sidebar-inner h2 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  background-color: #436b93;
  border-radius: 4px 4px 0 0;
  height: 20px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
}

:global #sidebar-inner #sidebar-inner-temp {
  color: #222;
  float: right;
  width: 210px;
  font-size: 12px;
  font-weight: 300;
  overflow: auto;
}

@media (width <= 1024px) {
  :global #sidebar-inner #sidebar-inner-temp {
    float: none;
    width: 100%;
    min-width: 200px;
    max-width: 480px;
  }
}

:global #sidebar-inner #sidebar-inner-temp div.sidebar-section {
  background-color: #ddd;
  padding-bottom: 10px;
}

@media (width >= 769px) and (width <= 1024px) {
  :global #ki_container.ki_right {
    right: 15px;
  }
}

@media (width >= 569px) and (width <= 768px) {
  :global #ki_container.ki_right {
    right: 10px;
  }
}

@media (width <= 568px) {
  :global #ki_container.ki_right {
    width: 310px;
    right: 5px;
  }
}

@media (width <= 1024px) {
  :global #ki_tab {
    margin-right: 0;
  }
}

:global a {
  cursor: pointer;
  color: #436b93;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
}

:global a:hover {
  color: #204664;
}
