:global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer {
  color: #222;
  right: 14px;
}

:global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer h2 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

:global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer h3 {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 18px;
  font-weight: 300;
}

@media (width <= 768px) {
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer {
    border-radius: 0;
    max-width: 100%;
    height: 100%;
    margin: 0;
    right: 0;
  }
}

@media (width >= 1025px) {
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer {
    right: 18px;
  }
}

@media (width <= 768px) {
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button[embeddedService-chatHeader_chatHeader] {
    width: unset;
  }

  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button[embeddedService-chatHeader_chatHeader]:hover:before {
    right: unset;
    margin-left: -1px;
  }

  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button[embeddedService-chatHeader_chatHeader] svg {
    height: 100%;
  }

  :is(:global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button.minimizeButton, :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button.closeButton) {
    font-size: 9px !important;
  }

  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer .embeddedServiceLiveAgentStateChatInputFooter.chasitorInputWrapper {
    border-radius: 0;
  }
}

:global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer .embeddedServiceSidebarButton .label {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

:global .embeddedServiceSidebar.sidebarMaximized.modalContainer {
  z-index: 10031;
}

:global .embeddedServiceSidebar.sidebarMinimized.modalContainer {
  z-index: 200;
}
