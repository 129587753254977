:local .badge {
  display: inline-block;
  height: 19px;
  min-width: 19px;
  border-radius: 9.5px;
  background-color: #f3f3f3;
  color: #000;
  color: #fff;
  background-color: #436b93;
}
:local .badgeMedium {
  height: 29px;
  min-width: 29px;
  border-radius: 14.5px;
}
:local .badgeNeutral {
  color: #000;
  background-color: #f3f3f3;
}
:local .badgeAlert {
  background-color: #cc0000;
  color: #fff;
}
:local .badgeWarning {
  background-color: #f07f04;
}
:local .badgePill {
  background-color: #f6f3eb;
  color: #000;
  color: #fff;
  background-color: #436b93;
}
:local .badgeInteractive {
  cursor: default;
}
:local .badgeWhite {
  color: #222;
  background-color: #fff;
}
:local .badgeBuyerTertiary {
  background-color: #f6f3eb;
}
:local .badgeDealerTertiary {
  color: #222;
  background-color: #dee3eb;
}
:local .badgeMoonstone {
  background-color: #f3f3f3;
  color: #000;
}
:local .badgeCyan200 {
  color: #193148;
  background-color: #c6e6f1;
}
:local .badgeYellow200 {
  background-color: #ffdf8b;
  color: #5d4a20;
}
:local .badgeGreen200 {
  background-color: #ceecc1;
  color: #0e3c1e;
}
:local .badgeRed200 {
  background-color: #fecaca;
  color: #770303;
}
:local .badgeAlertSecondary {
  background-color: #950808;
  color: #fff;
}
:local .badgeInner {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  line-height: 1;
  letter-spacing: 0.5px;
  padding: 2px 5px 0;
  transform: translateY(-1px);
}
:local .badgeInnerPill {
  padding: 2px 9px 0;
}
:local .badgeInnerSmallHeavy {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 600;
}
:local .badgeInnerSmallRegular {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  font-weight: 300;
}
:local .badgeInnerMediumRegular {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  padding-top: 0;
}
:local .badgeInnerMediumHeavy {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  padding-top: 0;
}
:local .badgeInnerCapitalize {
  text-transform: none;
  text-transform: capitalize;
}
:local .badgeInnerUppercase {
  text-transform: none;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}