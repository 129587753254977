:local .wrapper {
  position: relative;
}
:local .dropdown {
  background-color: #fff;
  position: absolute;
  z-index: 300;
  box-sizing: border-box;
  border: 1px solid #436b93;
  border-bottom: none;
  border-top: none;
}
:local .matchContainerWidth {
  width: 100%;
}
:local .dropdownExpanded {
  border: 1px solid #436b93;
}
:local .spillOutOfWindow {
  position: fixed;
}
:local .dropdownShadow {
  box-shadow: 0px 1px 1px 0px #888;
}
:local .hideDropdownBorderTop {
  border-top: none !important;
}
:local .hideDropdownBorderBottom {
  border-bottom: none;
}
:local .reverseDirection {
  bottom: 0;
}
:local .alignRight {
  right: 0;
}
:local .detachedMode {
  top: 9px;
  border: none;
  filter: drop-shadow(2px 4px 4px #888);
  width: 130%;
}
:local .borderless {
  border: none !important;
  border-radius: 5px;
  box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.25);
}