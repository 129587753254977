:global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer {
  right: 14px;
  color: #222;
}
:global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer h2 {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
}
:global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer h3 {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 18px;
  font-weight: 300;
}
@media (max-width: 768px) {
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer {
    right: 0;
    height: 100%;
    max-width: 100%;
    margin: 0;
    border-radius: 0;
  }
}
@media (min-width: 1025px) {
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer {
    right: 18px;
  }
}
@media (max-width: 768px) {
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button[embeddedService-chatHeader_chatHeader] {
    width: unset;
  }
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button[embeddedService-chatHeader_chatHeader]:hover::before {
    right: unset;
    margin-left: -1px;
  }
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button[embeddedService-chatHeader_chatHeader] svg {
    height: 100%;
  }
}
@media (max-width: 768px) {
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button {
    /*
     * Fixes bug for SF chat window where minimize and close buttons
     * are not visible on mobile devices
     * https://trailhead.salesforce.com/trailblazer-community/feed/0D54S00000Dcp7NSAR
     *
     * Note: emulating mobile device shows buttons without the fix
     */
  }
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button.minimizeButton, :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer button.closeButton {
    font-size: 9px !important;
  }
}
@media (max-width: 768px) {
  :global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer .embeddedServiceLiveAgentStateChatInputFooter.chasitorInputWrapper {
    border-radius: 0;
  }
}
:global .embeddedServiceSidebar.layout-docked .dockableContainer.showDockableContainer .embeddedServiceSidebarButton .label {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}
:global .embeddedServiceSidebar.sidebarMaximized.modalContainer {
  z-index: 10031;
}
:global .embeddedServiceSidebar.sidebarMinimized.modalContainer {
  /**
   * Ensures minimised SF chat button is rendered under bottom
   * banners, so it would not cover banner close button
   */
  z-index: 200;
}