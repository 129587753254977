.link {
  line-height: unset;
  color: #436b93;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  transition: color .2s;
}

.link:hover {
  color: #000;
}

@media (hover: none) {
  .link:hover {
    color: #436b93;
  }
}

.underline {
  text-decoration: underline;
}

.dashed {
  -webkit-text-decoration: underline dashed;
  text-decoration: underline dashed;
  text-decoration-thickness: .5px;
}
