:local .inputContainer {
  box-sizing: border-box;
  background: #fff;
  border: thin solid #ddd;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  transition: border-color .15s ease-in;
  display: flex;
  position: relative;
}

:local .borderError {
  border-color: #c00;
}

:local .borderDark {
  border-color: #000 !important;
}

:local .disabled {
  cursor: not-allowed;
  background: #ddd !important;
}

:local .borderFocus {
  border-color: #436b93;
}

:local .heightLarge {
  height: 50px;
}

:local .heightMedium {
  height: 40px;
}

:local .heightSmall {
  height: 33px;
}

:local .heightDefault {
  height: 40px;
}

:local .noBorder {
  border: none;
}
