:local .labelWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: unset;
}
:local .label {
  flex: 1;
  flex-grow: 1;
}
:local .stringLabel {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #436b93;
}
:local .stringLabel:hover {
  color: #204664;
}
:local .arrow {
  flex-grow: 0;
  fill: currentColor;
  width: 16px;
  height: 16px;
  margin-left: 9px;
  transition: transform 0.2s ease-in-out;
  transform: translateY(calc(50% - 8px));
}
:local .arrowUp {
  transform: rotate(180deg) translateY(calc(-50% + 8px));
}
:local .expandingArea {
  height: 0;
  overflow-y: auto;
  transition: height 0.2s linear;
  position: relative;
}
:local .noScroll {
  overflow-y: hidden;
}
:local .showOverflow {
  overflow: visible;
}
:local .gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 27px;
  z-index: 10;
}
@supports (--cssVar: red) {
  @supports (-webkit-line-clamp: var(--expandingAreaLineClamp)) {
    :local .lineClamp {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: var(--expandingAreaLineClamp);
    }
    :local .lineClamp {
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
    }
  }
}
:local .hideContent {
  visibility: hidden;
}